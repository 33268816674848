import React, { FunctionComponent, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Anchor, Question } from '../../App.styles';
import { Button, NavFooter, PhotosModal, Result, ShowMeModal } from '../../components';
import { ResultInfo } from '../../components/Result/Result.styles';
import {
  closeAmzModal,
  delay,
  iconUrl,
  imagesUrl,
  isAmazonHost,
  isEmpty,
  metricEvents,
  MetricProperty,
  TestId,
} from '../../utils';
import { useNavigate } from 'react-router-dom';
import { BrandsContext, FlowStep, GlobalContext, StepperContext, updateFlowStep } from '../../contexts';
import { SchemePreference } from '../../api';
import { useMetrics, useTimeSpentMetric } from '../../hooks';
import { environment } from '../../utils/environment';
import { Treatment } from '../../weblab/treatment';

const InfoNeeded: FunctionComponent = () => {
  const { t } = useTranslation('common');
  const { dispatch } = React.useContext(GlobalContext);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const { stepperData, updateStepperData } = useContext(StepperContext);
  const { brands } = useContext(BrandsContext);
  const [labelText] = useState(
    stepperData.searchBy === SchemePreference.ModelName
      ? 'results.infoNeeded.tryPortLabels'
      : 'results.infoNeeded.tryModel'
  );
  const { treatment } = environment;

  const shouldSkipPortLabels = React.useMemo(() => {
    const selectedBrand = brands?.find(
      (obj) => obj.brand_name === stepperData.brand
    );
    const emptyPortLabels = isEmpty(selectedBrand?.terminal_labels);
    return (
      emptyPortLabels && stepperData.searchBy === SchemePreference.ModelName
    );
  }, [brands, stepperData.brand, stepperData.searchBy]);
  const navigate = useNavigate();
  const { track } = useMetrics();

  useTimeSpentMetric('infoNeeded');

  const navigateToSchemePreference = async () => {
    track(metricEvents.tryOtherSchemeBtn, {
      [MetricProperty.selectedBrand]: stepperData.brand,
      [MetricProperty.selectedModel]: stepperData.model,
      [MetricProperty.terminals]: stepperData.terminals,
      [MetricProperty.searchPreference]: stepperData.searchBy,
    });

    const newSearchBy =
      stepperData.searchBy === SchemePreference.ModelName
        ? SchemePreference.TerminalLabels
        : SchemePreference.ModelName;
    const overrideStepperData = {
      ...stepperData,
      activeStep: treatment === Treatment.V3_5 ? 2 : 6,
      searchBy: newSearchBy,
      ...(newSearchBy === SchemePreference.TerminalLabels && {
        model: undefined,
      }),
      ...(newSearchBy === SchemePreference.ModelName && {
        terminals: undefined,
      }),
    };

    updateStepperData({ ...overrideStepperData });

    if (isAmazonHost()) {
      dispatch(updateFlowStep({ flowStep: FlowStep.compatibilityChecker }));
    } else {
      await delay(250);
      navigate('/');
    }
  };

  return (
    <>
      <Result
        image={{
          source: imagesUrl.info_needed,
          altText: t('images.infoNeeded'),
        }}
      >
        <div className="row h-100 flex-column" data-testid={TestId.InfoNeeded}>
          <div className="flex-grow-1">
            <div className="mt-2 mt-md-4">
              <img src={iconUrl.questionMark} alt="question"></img>
            </div>

            <Question className="my-3">
              {t('results.infoNeeded.title')}
            </Question>

            <ResultInfo>
              <Trans
                i18nKey={
                  environment.newInfoNeededCopy
                    ? 'common:results.infoNeeded.description'
                    : 'common:results.infoNeeded.oldDescription'
                }
              >
                description{' '}
                <Anchor
                  noUnderline
                  href={
                    environment.newInfoNeededCopy
                      ? t('results.infoNeeded.supportNumberLink')
                      : t('results.infoNeeded.oldSupportNumberLink')
                  }
                >
                  email
                </Anchor>
              </Trans>
            </ResultInfo>

            <div className="mb-3">
              <Anchor
                tabIndex={0}
                role="button"
                onClick={() => setShowRemoveModal(true)}
              >
                {t('results.showMeCover')}
              </Anchor>
            </div>
            <div className="mb-3">
              <Anchor
                tabIndex={0}
                role="button"
                onClick={() => setShowPhotoModal(true)}
              >
                {t('results.showMePhoto')}
              </Anchor>
            </div>
            {stepperData.brand &&
              stepperData.searchBy &&
              !shouldSkipPortLabels && (
                <Button
                  className="mt-5"
                  secondary
                  onClick={navigateToSchemePreference}
                  style={{ minWidth: 240 }}
                >
                  {t(labelText)}
                </Button>
              )}
          </div>

          <NavFooter>
            {isAmazonHost() ? (
              <>
                <Button type="button" onClick={closeAmzModal}>
                  {t('header.close')}
                </Button>
                <Button
                  type="button"
                  ghost
                  onClick={() => {
                    dispatch(
                      updateFlowStep({
                        flowStep: FlowStep.compatibilityChecker,
                      })
                    );
                  }}
                >
                  {t('header.back')}
                </Button>
              </>
            ) : (
              <a href={t('results.homeUrl')}>
                <Button type="button" className="w-100">
                  {t('results.shopRing')}
                </Button>
              </a>
            )}
          </NavFooter>
        </div>
      </Result>

      {showRemoveModal ? (
        <ShowMeModal closeModal={() => setShowRemoveModal(false)}></ShowMeModal>
      ) : null}

      {showPhotoModal ? (
        <PhotosModal closeModal={() => setShowPhotoModal(false)}></PhotosModal>
      ) : null}
    </>
  );
};

export default InfoNeeded;
