// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce<F extends (...params: any[]) => void>(
  fn: F,
  delay = 1000
) {
  let timeoutID: number | null = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (this: any, ...args: any[]) {
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    timeoutID = window.setTimeout(() => fn.apply(this, args), delay);
  } as F;
}
