import { isAmazonHost, isMshop } from './isAmazonHost';
import { getUserAgentInfo } from './getUserAgentInfo';

/* Logic for debugging a.com version in localhost */
const handleLocalDebugging = () => {
  if (window.location.hostname.includes('localhost')) {
    document.querySelector('body').style.background = '#f2f4f4';
    const localModalSim = document.createElement('div');
    localModalSim.classList.add('localhost-amz-modal');
    localModalSim.classList.add('a-popover');
    localModalSim.classList.add('a-popover-modal');
    while (document.body.childNodes.length > 0) {
      localModalSim.appendChild(document.body.childNodes[0]);
    }
    document.body.appendChild(localModalSim);
  }
};

const handleMshopLogic = () => {
  if (!isMshop()) return;
  const ua = getUserAgentInfo();
  const root = document.querySelector('#root');
  const parentModal = root.closest('.a-popover');
  const NO_OVERFLOW_CLASSNAME = 'rcc-body-no-overflow';
  const observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      if (mutation.type === 'attributes') {
        const isHidden = mutation.target.getAttribute('aria-hidden') === 'true';
        if (!isHidden) {
          document.body.classList.add(NO_OVERFLOW_CLASSNAME);
        } else {
          document.body.classList.remove(NO_OVERFLOW_CLASSNAME);
        }
      }
    });
  });

  if (ua?.isAndroid) {
    root.classList.add('root-amz-mshop-android');
    parentModal.classList.add('rcc-mshop-modal-parent-android');
  } else {
    root.classList.add('root-amz-mshop');
    parentModal.classList.add('rcc-mshop-modal-parent');
  }

  document.body.classList.add(NO_OVERFLOW_CLASSNAME);
  observer.observe(parentModal, { attributes: true });
};

export const handleAmazonInitialRender = () => {
  if (!isAmazonHost()) return;

  const root = document.querySelector('#root');
  root.parentElement.classList.add('rcc-bootstrap');
  root.classList.add('root-amz');
  const parentModalContent = root.closest('.a-popover-inner');

  if (parentModalContent) {
    parentModalContent.style.padding = '0';
  }

  // Only applies to mobile browsers and mShop because of selector
  const mobileContentParentClassName = isMshop()
    ? 'rcc-mobile-content-parent-mshop'
    : 'rcc-mobile-content-parent';
  root
    .closest('.a-secondary-view-inner')
    ?.classList.add(mobileContentParentClassName);

  handleMshopLogic();
  handleLocalDebugging();

  // Center main modal after first load
  window.P?.when('A', 'a-modal').execute(function (A, modal) {
    const instance = modal.get('modal-key-hmc');
    instance?.updatePosition();
  });
};
