export enum TestId {
  Success = 'Success',
  InfoNeeded = 'InfoNeeded',
  NotCompatible = 'NotCompatible',
  InfoBox = 'InfoBox',
  Modal = 'Modal',
  Stepper = 'Stepper',
  BackplateSelection = 'BackplateSelection',
  BrandSelection = 'BrandSelection',
  CompatibilityOptions = 'CompatibilityOptions',
  Card = 'Card',
  NamingConventions = 'NamingConventions',
  HasBackplate = 'HasBackplate',
  Models = 'Models',
  ModelSelection = 'ModelSelection',
  ModelLoadingEmpty = 'ModelLoadingEmpty',
}
