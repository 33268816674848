import { GlobalActionType, FlowStep } from './GlobalContextTypes';

type Act<A, AT, P> = (args: A) => { type: AT; payload: P };

interface ActionArgs {
  flowStep: FlowStep;
}

export const updateFlowStep: Act<
  ActionArgs,
  GlobalActionType.UpdateFlowStep,
  ActionArgs
> = ({ flowStep }) => ({
  type: GlobalActionType.UpdateFlowStep,
  payload: { flowStep },
});

export type GlobalContextAction = ReturnType<typeof updateFlowStep>;
