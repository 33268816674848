const fontName = 'Equip';

export const Equip = `
  @font-face {
    font-family: ${fontName};
    src: url(https://static.ring.com/fonts/30DF1A_0_0.eot);
    src: url(https://static.ring.com/fonts/30DF1A_0_0.eot?#iefix) format("embedded-opentype"),
         url(https://static.ring.com/fonts/30DF1A_0_0.woff2) format("woff2"),
         url(https://static.ring.com/fonts/30DF1A_0_0.woff) format("woff"),
         url(https://static.ring.com/fonts/30DF1A_0_0.ttf) format("truetype"),
         url(https://static.ring.com/fonts/30DF1A_0_0.svg#wf) format("svg");
    font-weight: 300;
  }

  @font-face {
    font-family: ${fontName};
    src: url("https://static.ring.com/fonts/2BBA9E_0_0.eot") format("embedded-opentype"),
         url("https://static.ring.com/fonts/2BBA9E_0_0.woff") format("woff"),
         url("https://static.ring.com/fonts/2BBA9E_0_0.ttf") format("ttf");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: ${fontName};
    src: url(https://static.ring.com/fonts/2BA2B5_0_0.eot);
    src: url(https://static.ring.com/fonts/2BA2B5_0_0.eot?#iefix) format("embedded-opentype"),
         url(https://static.ring.com/fonts/2BA2B5_0_0.woff) format("woff"),
         url(https://static.ring.com/fonts/2BA2B5_0_0.ttf) format("truetype");
    font-weight: 700;
    font-style: normal;
  }
`;

export const IconFont = `
  @font-face {
    font-family: 'rs-iconfont';
    src: url(https://m.media-amazon.com/images/G/01/CosmosWeb/RingtercomCompatibilityChecker/rs-iconfont.ttf) format("truetype");
  }
`;
