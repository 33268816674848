import styled, { css } from 'styled-components';
import { WHITE, TERTIARY_500, NEUTRAL_300 } from '../../styles/colors';
import Checkbox from '../Checkbox';
import { IconFont } from '../../styles/font';
import { isAmazonHost } from '../../utils';

export const PortLabelsContainer = styled.div`
  ${() =>
    isAmazonHost() &&
    css`
      &&& {
        padding-bottom: 16px;
      }
    `}
`;

export const Container = styled.div<{ isAmazon?: boolean }>`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  > div {
    margin-bottom: 16px;
    width: 100%;
    max-width: calc(33% - 4px);
    margin-bottom: 16px;

    > label {
      ${({ isAmazon }) => !isAmazon && `max-width: calc(100% - 12px - 25px);`}
      word-wrap: break-word;
    }
  }
`;

export const FillGrid = styled.div`
  flex: auto;
`;

export const ExtraSpace = styled.div`
  ${() =>
    isAmazonHost() &&
    css`
      &&& {
        @media (max-width: 768px) {
          padding-bottom: 16px;
        }
      }
    `}
`;

export const ArrowButton = styled.button`
  margin-top: 8px;
  margin-bottom: 16px;
  padding: 16px 24px;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid ${TERTIARY_500};
  background: ${WHITE};
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    color: #424242;
  }

  > img {
    margin-left: 16px;
  }
`;

export const Description = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${NEUTRAL_300};
  padding-top: 8px;
`;

export const SymbolCheckbox = styled(Checkbox)`
  ${IconFont}
  font-family: 'rs-iconfont', Equip, Helvetica, Arial, sans-serif !important;

  ${() =>
    isAmazonHost() &&
    css`
      &&& label {
        margin-left: 0;
      }
    `}
`;
