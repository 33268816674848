export const closeAmzModal = () => {
  const instanceName = 'modal-key-hmc';
  ['a-modal', 'a-secondary-view'].forEach((popoverComponent) =>
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    (window as any).P.when('A', popoverComponent).execute(
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      function (A: any, popover: any) {
        const instance = popover.get(instanceName);
        if (instance) {
          instance.hide();
        }
      }
    )
  );
};
