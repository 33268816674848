import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor } from '../../App.styles';
import { PreferHumanRoot } from './PreferHuman.styles';
import { iconUrl } from '../../utils';

interface PreferHumanProps {
  onClick?: () => void;
}

const PreferHuman: FC<PreferHumanProps> = ({ onClick }) => {
  const { t } = useTranslation('common');

  return (
    <PreferHumanRoot className="row gx-2 align-items-center align-items-md-start">
      <div className="col-auto">
        <img className="icon" alt="phone" src={iconUrl.phoneCircle}></img>
      </div>
      <div className="col-auto">
        <div className="row gx-2" style={{ maxWidth: 215 }}>
          <span className="col-auto col-md-12 header-text">
            {t('help.preferHuman.title')}
          </span>
          <span className="col-auto col-md-12">
            <span>{t('help.preferHuman.description')} </span>
            <Anchor
              href={t('help.preferHuman.supportNumberLink')}
              target="_blank"
              onClick={() => onClick?.()}
            >
              {t('help.preferHuman.supportNumberDisplay')}
            </Anchor>
          </span>
        </div>
      </div>
    </PreferHumanRoot>
  );
};

export default PreferHuman;
