import { forwardRef, ReactNode, Ref } from 'react';
import { ButtonsContainer, Footer } from './NavFooter.styles';
import { isAmazonHost } from '../../utils';

interface NavFooterProps {
  children: ReactNode[] | ReactNode;
  xPadding?: number;
}

// eslint-disable-next-line react/display-name
const NavFooter = forwardRef(
  (props: NavFooterProps, ref: Ref<HTMLDivElement>) => {
    return (
      <Footer>
        <div className="row gx-5" ref={ref}>
          {isAmazonHost() ? (
            <ButtonsContainer className="border-top" xPadding={props.xPadding}>
              {props.children}
            </ButtonsContainer>
          ) : (
            <div className="d-grid gap-2 py-3 border-top">{props.children}</div>
          )}
        </div>
      </Footer>
    );
  }
);

export default NavFooter;
