import { FunctionComponent, ReactNode } from 'react';
import { InfoBoxRoot, InfoTitle } from './InfoBox.styles';
import { TestId, iconUrl, isAmazonHost } from '../../utils';

interface Props {
  title: string;
  description?: string;
  isHelp?: boolean;
  children?: ReactNode | ReactNode[];
}

const InfoBox: FunctionComponent<Props> = (props) => {
  const { title, description, isHelp, children } = props;

  return (
    <InfoBoxRoot className="px-4 py-3" isHelp={isHelp}>
      <div className="row" data-testid={TestId.InfoBox}>
        <div className="col-auto pe-0">
          <img
            src={isHelp ? iconUrl.questionDot : iconUrl.infoDot}
            alt="info box"
          ></img>
        </div>
        <div className="col">
          {isAmazonHost() ? <InfoTitle>{title}</InfoTitle> : <div>{title}</div>}
          {description && <div className="info-description">{description}</div>}
          {children ? <div className="mt-2">{children}</div> : null}
        </div>
      </div>
    </InfoBoxRoot>
  );
};

export default InfoBox;
