/**
 * Typography foundation
 * @see https://www.figma.com/file/O0FWE4TJWmIGCksbLUEL0i0y/Web-(Logged-In)?node-id=0%3A808
 */

const FONT_WEIGHT_LIGHT = `font-weight: 300;`;
const FONT_WEIGHT_REGULAR = `font-weight: 500;`;
const FONT_WEIGHT_MEDIUM = `font-weight: 600;`;
const FONT_WEIGHT_BOLD = `font-weight: 700;`;

/**
 * Headings (Medium)
 * font-size: 28px;
 * line-height: 36px;
 */
const HEADING_LARGE_MEDIUM = `
   font-size: 1.75rem;
   ${FONT_WEIGHT_MEDIUM}
   line-height: 2rem;
 `;

/**
 * font-size: 24px;
 * line-height: 32px;
 */
const HEADING_NORMAL_MEDIUM = `
   font-size: 1.5rem;
   ${FONT_WEIGHT_MEDIUM}
   line-height: 2rem;
 `;

/**
 * font-size: 20px;
 * line-height: 28px;
 */
const HEADING_SMALL_MEDIUM = `
   font-size: 1.25rem;
   ${FONT_WEIGHT_MEDIUM}
   line-height: 1.75rem;
 `;

/**
 * font-size: 20px;
 * line-height: 28px;
 */
const HEADING_SMALL_REGULAR = `
   font-size: 1.25rem;
   ${FONT_WEIGHT_REGULAR}
   line-height: 1.75rem;
 `;

/**
 * Headings (Light)
 * font-size: 20px;
 * line-height: 28px;
 */
const HEADING_SMALL_LIGHT = `
   font-size: 1.75rem;
   ${FONT_WEIGHT_LIGHT}
   line-height: 2rem;
 `;

/**
 * Body (Bold)
 * font-size: 16px;
 * line-height: 24px;
 */
const BODY_LARGE_BOLD = `
   font-size: 1.125rem;
   ${FONT_WEIGHT_BOLD}
   line-height: 1.5rem;
 `;

/**
 * font-size: 14px;
 * line-height: 24px;
 */
const BODY_NORMAL_BOLD = `
   font-size: 0.875rem;
   ${FONT_WEIGHT_BOLD}
   line-height: 1.5rem;
 `;

/**
 * Body (Medium)
 * font-size: 16px;
 * line-height: 24px;
 */
const BODY_LARGE_MEDIUM = `
   font-size: 1rem;
   ${FONT_WEIGHT_MEDIUM}
   line-height: 1.5rem;
 `;

/**
 * font-size: 14px;
 * line-height: 24px;
 */
const BODY_NORMAL_MEDIUM = `
   font-size: 0.875rem;
   ${FONT_WEIGHT_MEDIUM}
   line-height: 1.5rem;
 `;

/**
 * font-size: 12px;
 * line-height: 18px;
 */
const BODY_SMALL_MEDIUM = `
  font-size: 0.75rem;
  ${FONT_WEIGHT_MEDIUM}
  line-height: 1.125rem;
 `;

/**
 * Body (Regular)
 * font-size: 16px;
 * line-height: 24px;
 */
const BODY_LARGE_REGULAR = `
   font-size: 1rem;
   ${FONT_WEIGHT_REGULAR}
   line-height: 1.5rem;
 `;

/**
 * font-size: 14px;
 * line-height: 24px;
 */
const BODY_NORMAL_REGULAR = `
   font-size: 0.875rem;
   ${FONT_WEIGHT_REGULAR}
   line-height: 1.5rem;
 `;

/**
 * font-size: 12px;
 * line-height: 18px;
 */
const BODY_SMALL_REGULAR = `
   font-size: 0.75rem;
   ${FONT_WEIGHT_REGULAR}
   line-height: 1.125rem;
 `;

/**
 * Body (Light)
 * font-size: 16px;
 * line-height: 24px;
 */
const BODY_LARGE_LIGHT = `
   font-size: 1rem;
   ${FONT_WEIGHT_LIGHT}
   line-height: 1.5rem;
 `;

/**
 * font-size: 14px;
 * line-height: 24px;
 */
const BODY_NORMAL_LIGHT = `
   font-size: 0.875rem;
   ${FONT_WEIGHT_LIGHT}
   line-height: 1.5rem;
 `;

/**
 * font-size: 12px;
 * line-height: 18px;
 */
const BODY_SMALL_LIGHT = `
   font-size: 0.75rem;
   ${FONT_WEIGHT_LIGHT}
   line-height: 1.125rem;
 `;

/**
 * Textlink (Medium)
 * font-size: 16px;
 * line-height: 24px;
 */
const TEXTLINK_LARGE_MEDIUM = `
   font-size: 1rem;
   ${FONT_WEIGHT_MEDIUM}
   line-height: 1.5rem;
 `;

/**
 * font-size: 14px;
 * line-height: 24px;
 */
const TEXTLINK_NORMAL_MEDIUM = `
   font-size: 0.875rem;
   ${FONT_WEIGHT_MEDIUM}
   line-height: 1.5rem;
 `;

/**
 * font-size: 12px;
 * line-height: 18px;
 */
const TEXTLINK_SMALL_MEDIUM = `
   font-size: 0.75rem;
   ${FONT_WEIGHT_MEDIUM}
   line-height: 1.125rem;
 `;

/**
 * font-size: 11px;
 * line-height: 18px;
 */
const TEXTLINK_EXTRA_SMALL_MEDIUM = `
   font-size: 0.6875rem;
   ${FONT_WEIGHT_MEDIUM}
   line-height: 1.125rem;
 `;

/**
 * Footnote (Medium)
 * font-size: 11px;
 * line-height: 18px;
 */
const FOOTNOTE_XSMALL_MEDIUM = `
   font-size: 0.6875rem;
   ${FONT_WEIGHT_REGULAR}
   line-height: 1.125rem;
 `;

/**
 * Footnote (Regular)
 * font-size: 11px;
 * line-height: 18px;
 */
const FOOTNOTE_XSMALL_REGULAR = `
   font-size: 0.6875rem;
   ${FONT_WEIGHT_REGULAR}
   line-height: 1.125rem;
 `;

/**
 * Footnote (Light)
 * font-size: 11px;
 * line-height: 18px;
 */
const FOOTNOTE_XSMALL_LIGHT = `
   font-size: 0.6875rem;
   ${FONT_WEIGHT_LIGHT}
   line-height: 1.125rem;
 `;

export {
  FONT_WEIGHT_LIGHT,
  FONT_WEIGHT_REGULAR,
  FONT_WEIGHT_MEDIUM,
  FONT_WEIGHT_BOLD,
  HEADING_LARGE_MEDIUM,
  HEADING_NORMAL_MEDIUM,
  HEADING_SMALL_MEDIUM,
  HEADING_SMALL_REGULAR,
  HEADING_SMALL_LIGHT,
  BODY_LARGE_BOLD,
  BODY_NORMAL_BOLD,
  BODY_LARGE_MEDIUM,
  BODY_NORMAL_MEDIUM,
  BODY_SMALL_MEDIUM,
  BODY_LARGE_REGULAR,
  BODY_NORMAL_REGULAR,
  BODY_SMALL_REGULAR,
  BODY_LARGE_LIGHT,
  BODY_NORMAL_LIGHT,
  BODY_SMALL_LIGHT,
  TEXTLINK_LARGE_MEDIUM,
  TEXTLINK_NORMAL_MEDIUM,
  TEXTLINK_SMALL_MEDIUM,
  TEXTLINK_EXTRA_SMALL_MEDIUM,
  FOOTNOTE_XSMALL_MEDIUM,
  FOOTNOTE_XSMALL_LIGHT,
  FOOTNOTE_XSMALL_REGULAR,
};
