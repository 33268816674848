export interface Environment {
  basePath?: string;
  basename?: string;
  v2Weblab?: boolean;
  locale?: string;
  loggerUrl?: string;
  fuzzyScoreLimit?: number;
  newInfoNeededCopy?: boolean;
  coupon?: {
    code: string;
    amount: number;
    currency: string;
  };
  treatment?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const environment = (window as any).ENVIRONMENT as Environment;
