import styled, { css } from 'styled-components';
import {
  AMZ_FOCUS_RING,
  PRIMARY_100,
  PRIMARY_500,
  TERTIARY_700,
} from '../../styles/colors';

export const RadioContainer = styled.div<{ isAmazon?: boolean }>`
  &&& {
    position: relative;

    ${({ isAmazon }) =>
      isAmazon &&
      css`
        &:hover > input {
          border-width: 3px;
          border-color: #4394a9;
        }
      `}
  }
`;

export const RadioInput = styled.input<{ isAmazon?: boolean }>`
  &&& {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    cursor: pointer;

    position: absolute !important;
    left: 16px;
    bottom: initial !important;

    font: inherit;
    color: ${TERTIARY_700};
    border-radius: 50%;

    display: grid;
    place-content: center;

    ${({ isAmazon }) =>
      isAmazon
        ? css`
            border: 1px solid #888c8c;
            top: 3px;
            width: 16px !important;
            height: 16px !important;

            &:focus:before {
              content: '';
              width: 22px;
              height: 22px;
              border-radius: 50%;
              border: 3px solid ${AMZ_FOCUS_RING};
            }
          `
        : css`
            border: 1px solid ${TERTIARY_700};
            top: 16px;
            width: 1.5em !important;
            height: 1.5em !important;
          `}

    ${({ isAmazon }) =>
      !isAmazon &&
      css`
        &:before {
          content: '';

          border-radius: 50%;
          transform: scale(0);
          transition: 120ms transform ease-in-out;
          width: 10px;
          height: 10px;
          box-shadow: inset 1em 1em ${PRIMARY_500};
        }

        &:checked:before {
          transform: scale(1);
        }
      `}

  &:checked {
      color: ${PRIMARY_500};

      ${({ isAmazon }) =>
        isAmazon
          ? css`
              border-width: 4.5px !important;
              border-color: #017185;
            `
          : css`
              border: 2px solid ${PRIMARY_500};
            `}
    }

    &:focus {
      outline: none;
    }

    ${({ isAmazon }) =>
      !isAmazon &&
      css`
        &:checked + ${RadioLabel} {
          background-color: ${PRIMARY_100};
          border-color: ${PRIMARY_500};
        }
      `}
  }
`;

export const RadioLabel = styled.label<{
  breakWord?: string;
  isAmazon?: boolean;
}>`
  &&& {
    padding: 16px 16px 16px 56px;
    cursor: pointer;
    width: 100%;
    display: grid;
    word-wrap: break-word;
    max-width: 100%;
    word-break: ${({ breakWord }) => breakWord ?? 'initial'};
    font-weight: normal;

    ${({ isAmazon }) =>
      isAmazon
        ? css`
            padding-left: 40px;
            padding-top: 0px;
            padding-bottom: 0px;

            @media (max-width: 768px) {
              font-size: 16px !important;
              line-height: 22px !important;
            }
          `
        : css`
            border: 1px solid #dde5eb;
            border-radius: 4px;
          `}
  }
`;
