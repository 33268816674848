import { StepperContextData } from '../contexts';
import { getCookie, isAmazonHost } from '../utils';
import { environment } from '../utils/environment';

export const isMultidoor = (stepperData: StepperContextData) => {
  const { isParallel, multiDoor } = stepperData;
  return !!multiDoor && !isParallel;
};

type CountryCode = string | undefined;

export const getMarketplaceCountry = () => {
  const validateCode = (code: CountryCode): CountryCode => {
    return code?.length === 2 ? code : undefined;
  }

  let countryCode = isAmazonHost()
    // www.amazon.fr -> ['www', 'amazon', 'fr'] -> fr
    ? window.location.hostname.split('.')?.at(-1)
    : getCookie('chosen_country');


  if (!validateCode(countryCode)) {
    const localeCode = environment.locale?.split(/[_-]/)?.at(-1);
    const navigatorCode = navigator.language?.split(/[_-]/)?.at(-1)
    countryCode = validateCode(localeCode) ?? validateCode(navigatorCode)
  }

  return countryCode?.toUpperCase() ?? '';
};
