import styled, { css } from 'styled-components';
import { NEUTRAL_300, POSITIVE_500, WHITE } from '../../styles/colors';
import { isAmazonHost } from '../../utils';

interface MainContainerProps {
  isAmazon?: boolean;
}

export const MainContainer = styled.div<MainContainerProps>`
  ${({ isAmazon }) =>
    isAmazon &&
    css`
      padding-bottom: 50px;
    `}
`;

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    ${() =>
      !isAmazonHost() &&
      css`
        & > div:first-child {
          margin-right: 32px;
        }
      `}

    ${() =>
      isAmazonHost() &&
      css`
        justify-content: space-between;

        & > div {
          max-width: 48%;

          & img {
            height: auto !important;
          }

          & h4 {
            font-size: 16px !important;
            line-height: 20px !important;
            margin-bottom: 5px;
          }

          & p {
            font-size: 14px !important;
            line-height: 19px;
            margin-bottom: 10px !important;
          }
        }
      `}
  }
`;

export const CardData = styled.div`
  > h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
  }

  > p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: ${NEUTRAL_300};
    margin-bottom: 8px;
    margin-top: 0px;
  }
`;

export const Tag = styled.span`
  background-color: ${POSITIVE_500};
  color: ${WHITE};
  display: inline-block;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;
