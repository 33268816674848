import { TERTIARY_300, TERTIARY_500 } from '../../styles/colors';
import styled from 'styled-components';
import { BODY_NORMAL_REGULAR } from '../../styles/typography';

export const PreferHumanRoot = styled.div`
  border-bottom: 1px solid ${TERTIARY_500};
  background-color: ${TERTIARY_300};
  ${BODY_NORMAL_REGULAR};
  padding: 16px 24px;
  margin-right: -12px;
  margin-left: -12px;

  @media (min-width: 768px) {
    border: 1px solid ${TERTIARY_500};
    border-radius: 4px;

    .header-text {
      font-size: 1rem;
    }
  }

  .icon {
    width: 24px;

    @media (min-width: 768px) {
      width: 32px;
    }
  }
`;
