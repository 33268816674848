/**
 * Official Color System
 * @see https://www.figma.com/file/O0FWE4TJWmIGCksbLUEL0i0y/Web-(Logged-In)?node-id=0%3A1138
 */

export const BLACK = '#000';
export const WHITE = '#fff';

export const PRIMARY_800 = '#ADDAED';
export const PRIMARY_700 = '#10628A';
export const PRIMARY_600 = '#1476AD';
export const PRIMARY_500 = '#1998d5';
export const PRIMARY_400 = '#1476AD'; // TODO: This matches PRIMARY_600 in Figma... which doesn't even exist here
export const PRIMARY_200 = '#AFDBF1';
export const PRIMARY_100 = '#EFF8FC';

export const SECONDARY_700 = '#9C4308';
export const SECONDARY_500 = '#F1670D';
export const SECONDARY_400 = '#C74E00';
export const SECONDARY_200 = '#FACAAB';
export const SECONDARY_100 = '#FEF4EE';

export const TERTIARY_700 = '#81919F';
export const TERTIARY_500 = '#DEE5EC';
export const TERTIARY_300 = '#F6F8FA';

export const NEUTRAL_500 = '#424242';
export const NEUTRAL_300 = '#6E6E6E';
export const NEUTRAL_200 = '#BDBDBD';
export const NEUTRAL_100 = '#ECECEC';
export const NEUTRAL_50 = '#FAFBFC';

export const NEGATIVE_700 = '#7C1212';
export const NEGATIVE_500 = '#D4231A';
export const NEGATIVE_400 = '#A72721';
export const NEGATIVE_200 = '#F0B2AF';
export const NEGATIVE_100 = '#FCEFEF';

export const LIGHT_NEGATIVE_BASE = '#D9342A';

export const POSITIVE_700 = '#22580E';
export const POSITIVE_500 = '#178019';
export const POSITIVE_400 = '#157217';
export const POSITIVE_200 = '#AED3AF';
export const POSITIVE_100 = '#EFF6EF';

export const AMAZON_700 = '#A66300'; // Pressed state
export const AMAZON_500 = '#FF9900'; // Main use
export const AMAZON_200 = '#FFDBA6'; // Disabled state

export const CAUTION_500 = '#FDD835';
export const CAUTION_700 = '#A48C22';

// Named Colors
export const NATIVE_GREY = '#808080';
export const GREY = '#bdbdbd';
export const GREY_LIGHT = '#dadada';

export const SNOW = '#fafafa';
export const GREY_78 = '#c7c7c7';
export const FORD_GREY = '#979797';
export const GREY_53 = '#878787';

export const BACKGROUND = NEUTRAL_300;
export const BACKGROUND_2 = NEUTRAL_50;

export const MUTED = '#cccccc';

export const SURFACE = WHITE;
export const ON_SURFACE = NEUTRAL_500;

export const LIVE = NEGATIVE_500;

export const CRITICAL = NEGATIVE_500;

export const DEFAULT_BORDER_COLOR = TERTIARY_500;
export const HOVER_BORDER_COLOR = PRIMARY_500;
export const SELECTED_BORDER_COLOR = HOVER_BORDER_COLOR;

export const NEXT_DOOR_GREEN = '#40A142';
export const FACEBOOK_BLUE = '#006494';
export const TWITTER_PRIMARY = '#4DC2FA';

export const LIGHT_CONTENT = 'rgba(66, 66, 66, .65)';
// Amazon colors
export const AMZ_BLACK = '#0f1111';

export const AMZ_PRIMARY_500 = '#0275ff';

export const AMZ_FOCUS_RING = '#d0f2f9';

export const AMZ_YELLOW_600 = '#E8B93F';
export const AMZ_YELLOW_500 = '#ffdc00';
export const AMZ_YELLOW_400 = '#F1CB45';
export const AMZ_YELLOW_300 = '#ffed94';
export const AMZ_YELLOW_200 = '#fffae0';
