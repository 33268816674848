import {
  FunctionComponent,
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { Button, NavFooter } from '../';
import {
  CloseButton,
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalTitle,
} from './Modal.styled';
import { iconUrl, isAmazonHost } from '../../utils';

interface Props {
  children: ReactNode;
  modalTitle: string;
  closeAction: () => void;
  className?: string;
}

const Modal: FunctionComponent<Props> = ({
  children,
  modalTitle,
  closeAction,
  className,
}) => {
  const { t } = useTranslation('common');

  const elRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  if (!elRef.current) {
    const divEl = document.createElement('div');
    divEl.classList.add('h-xs-100');
    if (isAmazonHost()) {
      divEl.classList.add('h-100');
      divEl.classList.add('w-100');
    }
    elRef.current = divEl;
  }

  useEffect(() => {
    const modalRoot = document.getElementById('modal');
    if (!modalRoot || !elRef.current) {
      return;
    }
    const rootEl = document.querySelector('.root-amz-mshop');
    rootEl?.classList.add('root-mshop-hidden');
    modalRoot.appendChild(elRef.current);
    return () => {
      if (elRef.current) {
        rootEl?.classList.remove('root-mshop-hidden');
        modalRoot.removeChild(elRef.current);
      }
    };
  }, []);

  return createPortal(
    <ModalContainer
      className={`container-fluid ${className || ''}`}
      isAmazon={isAmazonHost()}
    >
      {!isAmazonHost() && (
        <ModalHeader className="row">
          <div className="col-12">
            <ModalTitle className="row align-items-center">
              <div className="col text-center">{modalTitle}</div>
              <CloseButton type="button" className="btn" onClick={closeAction}>
                <img src={iconUrl.close} alt={t('header.close')}></img>
              </CloseButton>
            </ModalTitle>
          </div>
        </ModalHeader>
      )}
      <ModalBody className="row" isAmazon={isAmazonHost()}>
        <div className="col gx-5">{children}</div>
        {isAmazonHost() && (
          <NavFooter xPadding={36}>
            <Button type="button" secondary onClick={closeAction}>
              {t('header.back')}
            </Button>
          </NavFooter>
        )}
      </ModalBody>
    </ModalContainer>,
    elRef.current
  );
};

export default Modal;
