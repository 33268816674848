import { REDIRECT_URL_PARAM, redirectUrls } from './constants';

export const getRedirectUrl = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const redirectParam = searchParams.get(REDIRECT_URL_PARAM);

  if (!redirectParam) {
    return null;
  }

  return redirectUrls.find((urlObject) => urlObject.url === redirectParam);
};
