import styled, { css } from 'styled-components';
import { NEUTRAL_500, TERTIARY_500, WHITE } from '../../styles/colors';
import { Equip } from '../../styles/font';

export const ModalContainer = styled.div<{ isAmazon?: boolean }>`
  background-color: white;
  position: relative;
  color: ${NEUTRAL_500};
  height: 100%;

  ${({ isAmazon }) =>
    !isAmazon
      ? css`
          &&& {
            ${Equip}
            font-family: Equip,Helvetica,Arial,sans-serif !important;
            width: 100vw;

            @media (min-width: 768px) {
              width: auto;
              height: 650px;
              width: 650px;
              max-height: 80vh;
            }
          }
        `
      : css`
          font-family: 'Amazon Ember', Arial, sans-serif !important";
      `};
`;

export const ModalHeader = styled.div`
  height: 60px;
  border-bottom: 1px solid ${TERTIARY_500};
  position: absolute;
  top: 0;
  background-color: ${WHITE};
  width: 100%;
`;

export const ModalTitle = styled.div`
  height: 60px;
`;

export const CloseButton = styled.button`
  width: auto !important;
  position: absolute;
  right: 0;
`;

export const ModalBody = styled.div<{ isAmazon?: boolean }>`
  padding-top: ${({ isAmazon }) => (isAmazon ? '25px' : '80px')};
  height: 100%;
  overflow-y: scroll;

  ${({ isAmazon }) =>
    isAmazon &&
    css`
      flex-direction: column;
      flex-wrap: nowrap !important;
      overflow-x: hidden;
    `}
`;
