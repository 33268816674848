import React, { FC, ReactNode, useMemo } from 'react';
import Header from '../Header/Header';
import { ActiveStep, BottomBorder, StepIndicator } from './Stepper.styles';
import { isAmazonHost, TestId } from '../../utils';
import { FlowStep, GlobalContext } from '../../contexts';

interface StepperProps {
  children: ReactNode[] | ReactNode;
  onBack: () => void;
  hasBackButton: boolean;
  activeStep: number;
  headerTitle: string;
  showStepIndicator?: boolean;
}

const Stepper: FC<StepperProps> = ({
  children,
  activeStep,
  onBack,
  hasBackButton,
  showStepIndicator,
  headerTitle,
}) => {
  const {
    state: { flowStep },
  } = React.useContext(GlobalContext);
  const steps = useMemo<ReactNode[]>(
    () => (Array.isArray(children) ? [...children] : [children]),
    [children]
  );

  const renderHeaderContent = () => {
    if (showStepIndicator) {
      return (
        <StepIndicator
          progress={(activeStep + 1) / (steps.length ?? 1)}
          isAmazon={isAmazonHost()}
        />
      );
    }

    if (
      !isAmazonHost() ||
      (isAmazonHost() && flowStep === FlowStep.compatibilityChecker)
    ) {
      return <BottomBorder />;
    }
    return <></>;
  };

  return (
    <div className="h-100" data-testid={TestId.Stepper}>
      <Header
        onBack={onBack}
        hasBackButton={hasBackButton}
        headerTitle={headerTitle}
      >
        {renderHeaderContent()}
      </Header>
      <ActiveStep isAmazon={isAmazonHost()}>{steps[activeStep]}</ActiveStep>
    </div>
  );
};

export default Stepper;
