import React from 'react';
import { Button as StyledButton } from './Button.styles';
import { AmazonButton as StyledAmazonButton } from './AmazonButton.styles';
import { isAmazonHost } from '../../utils';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  ghost?: boolean;
  secondary?: boolean;
}

const Button: React.FC<ButtonProps> = (props) =>
  isAmazonHost() ? (
    <StyledAmazonButton {...props} />
  ) : (
    <StyledButton {...props} />
  );

export default Button;
