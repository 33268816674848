import i18n from 'i18next';
import common_en from './translations/en/common.json';
import common_de from './translations/de/common.json';
import common_fr from './translations/fr/common.json';
import common_it from './translations/it/common.json';
import common_es from './translations/es/common.json';
import common_nl from './translations/nl/common.json';
import { getLanguage } from './utils';

i18n.init({
  interpolation: { escapeValue: false },
  fallbackLng: [navigator.languages?.[0].split('-')[0], 'en'],
  lng: getLanguage(),
  resources: {
    en: {
      common: common_en,
    },
    de: {
      common: common_de,
    },
    fr: {
      common: common_fr,
    },
    it: {
      common: common_it,
    },
    es: {
      common: common_es,
    },
    nl: {
      common: common_nl,
    },
  },
});

export default i18n;
