import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useMetrics } from '../../hooks';
import { Question } from '../../App.styles';
import RadioGroup, { RadioOption } from '../RadioGroup/RadioGroup';
import { MetricProperty, metricEvents } from '../../utils';

interface Props {
  question: string;
  value?: boolean;
  setValue?: (val?: boolean) => void;
  groupName: string;
  yesAction?: () => void;
  noAction?: () => void;
  yesText?: string;
  noText?: string;
}

const PreQualificationQuestion: FunctionComponent<Props> = ({
  question,
  value,
  setValue,
  yesAction,
  noAction,
  groupName,
  yesText,
  noText,
}) => {
  const { t } = useTranslation('common');
  const { track } = useMetrics();

  const options: RadioOption<boolean>[] = [
    {
      id: 'yes',
      label: yesText || t('responses.yes'),
      value: true,
      onClick: () => yesAction?.(),
    },
    {
      id: 'no',
      label: noText || t('responses.no'),
      value: false,
      onClick: () => noAction?.(),
    },
  ];

  return (
    <div>
      <Question className="mb-3">{question}</Question>
      <RadioGroup
        groupName={groupName}
        options={options}
        value={value}
        setValue={(newValue) => {
          setValue?.(newValue as boolean);
          track(metricEvents.choice, {
            [MetricProperty.question]: question,
            [MetricProperty.groupName]: groupName,
            [MetricProperty.choice]: newValue ? 'yes' : 'no',
          });
        }}
      />
    </div>
  );
};

export default PreQualificationQuestion;
