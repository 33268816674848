export enum StepKey {
  HasIntercom = 'hasIntercom',
  HasMultipleDoors = 'hasMultipleDoors',
  IsParallel = 'isParallel',
  IsParallelAfterSuccess = 'isParallelAfterSuccess',
  HasOneButton = 'hasOneButton',
  FindBrand = 'findBrand',
  FindModel = 'findModel',
  NamingConventions = 'NamingConventions',
  Models = 'Models',
  CompatibilityOptions = 'CompatibilityOptions',
  HasBackplate = 'hasBackplate',
  BackplateSelection = 'BackplateSelection',
  ModelLoadingEmpty = 'ModelLoadingEmpty',
}
