import styled, { css } from 'styled-components';
import { PRIMARY_200 } from '../../styles/colors';
import { isAmazonHost } from '../../utils';

export const ImageContainer = styled.div`
  position: relative;
  background-color: ${PRIMARY_200};

  img {
    max-height: 100%;
  }
`;

export const PreferHumanContainer = styled.div`
  position: fixed;
  bottom: 24px;
  left: 32px;
`;

interface StepContentProps {
  preferHuman?: boolean;
}

export const StepContent = styled.div<StepContentProps>`
  height: 100%;

  ${(props) =>
    props.preferHuman &&
    css`
      height: ${isAmazonHost() ? 'inherit' : 'calc(100% - 81px)'};

      @media (min-width: 768px) {
        height: 100%;
      }
    `}
`;

export const StepContentContainer = styled.div`
  overflow-y: scroll !important;

  ${() =>
    isAmazonHost() &&
    css`
      @media (max-width: 768px) {
        overflow-y: auto !important;
      }
    `}
`;
