import { FunctionComponent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Question } from '../../App.styles';
import Button from '../Button';
import InfoBox from '../InfoBox/InfoBox';
import NavFooter from '../NavFooter/NavFooter';
import { useTranslation } from 'react-i18next';
import { ScreenOutcomeType, useHandleOutcome, useMetrics } from '../../hooks';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { Candidate } from '../../api';
import RadioGroup, { RadioOption } from '../RadioGroup/RadioGroup';
import { AppOutcome, StepKey } from '../../types';
import { Description } from './Models.styles';
import { formatCandidates, getModelOutcome, isAmazonHost, metricEvents, MetricProperty, TestId } from '../../utils';
import { CandidateModelsContext, StepperContext, StepperContextData } from '../../contexts';
import { ReasonOutcomeType } from '../../hooks/useHandleOutcome';

interface ModelsProps {
  next: (customData?: Partial<StepperContextData>) => void;
  onBack: () => void;
}

const Models: FunctionComponent<ModelsProps> = ({ next, onBack }) => {
  const { t } = useTranslation('common');
  const { track } = useMetrics();
  const navFooter = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);
  const [models, setModels] = useState([] as RadioOption<Candidate>[]);
  const [model, setModel] = useState<Candidate>();
  const { handleOutcome } = useHandleOutcome();
  const { data } = useContext(CandidateModelsContext);
  const { stepperData, updateStepperData } = useContext(StepperContext);
  const { brand, terminals } = stepperData;

  const handleModelsOutcome = useCallback(
    (outcome: AppOutcome, reason: ReasonOutcomeType, model?: Candidate) => {
      handleOutcome({
        value: outcome,
        screen: ScreenOutcomeType.SelectModelsFound,
        brand,
        terminals,
        reason,
        model,
      });
    },
    [brand, handleOutcome, model, terminals]
  );

  useEffect(() => {
    updateStepperData({ fallbackToModel: false });
  }, []);

  useEffect(() => {
    if (data) {
      if (data.candidates.length) {
        setModels(formatCandidates(data));
        setLoading(false);
      } else {
        handleModelsOutcome(
          getModelOutcome(data.estimated_support_status),
          ReasonOutcomeType.EarlyResult
        );
      }
    }
  }, [data]);

  const handleContinue = () => {
    if (model) {
      if (model.support === 'possible_support') {
        next({ fallbackToModel: true });
        return;
      }

      updateStepperData({ fallbackToModel: false, candidate: model });
      handleModelsOutcome(
        getModelOutcome(model.support),
        ReasonOutcomeType.Complete,
        model
      );
    }
  };

  const handleNeedHelp = () => {
    track(metricEvents.needHelpBtn, {
      [MetricProperty.pageName]: StepKey.Models,
    });
    handleModelsOutcome(AppOutcome.InfoNeeded, ReasonOutcomeType.HelpClick);
  };

  return (
    <div className="row h-100 flex-column" data-testid={TestId.Models}>
      <Question className="col-12">{t('prompts.modelsFound')}</Question>
      <Description className="col-12 mb-3">
        {t('help.models.chooseModel')}
      </Description>

      <div className="flex-grow-1">
        {loading ? (
          <LoadingIndicator />
        ) : models.length ? (
          <RadioGroup
            breakWord="break-all"
            groupName="model"
            options={models}
            value={model}
            setValue={(val) => setModel(val)}
          />
        ) : (
          <span
            role="button"
            className="d-block mt-3"
            onClick={() =>
              handleModelsOutcome(
                AppOutcome.InfoNeeded,
                ReasonOutcomeType.NoSearchResultsClick
              )
            }
          >
            <InfoBox
              isHelp
              title={t('help.noResults.title')}
              description={t('help.noResults.description')}
            ></InfoBox>
          </span>
        )}
      </div>
      <NavFooter ref={navFooter}>
        <Button type="button" disabled={!model} onClick={handleContinue}>
          {t('buttons.continue')}
        </Button>
        {isAmazonHost() && (
          <>
            <Button type="button" ghost onClick={onBack}>
              {t('header.back')}
            </Button>
            <div style={{ marginRight: 'auto' }} />
          </>
        )}
        <Button type="button" ghost onClick={handleNeedHelp}>
          {t('buttons.needHelp')}
        </Button>
      </NavFooter>
    </div>
  );
};

export default Models;
