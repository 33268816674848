import styled, { css } from 'styled-components';
import {
  AMZ_FOCUS_RING,
  AMZ_YELLOW_200,
  AMZ_YELLOW_300,
  AMZ_YELLOW_400,
  AMZ_YELLOW_500,
  AMZ_YELLOW_600,
  AMZ_BLACK,
} from '../../styles/colors';

interface ButtonProps {
  ghost?: boolean;
  secondary?: boolean;
}

export const AmazonButton = styled.button<ButtonProps>`
  &&& {
    background-color: ${AMZ_YELLOW_500};
    border: 1px solid ${AMZ_YELLOW_500};
    border-radius: 8px;
    padding: 4px 10px;
    transition: 120ms border ease-in-out;
    color: ${AMZ_BLACK};
    font-weight: 400;
    font-size: 14px;

    &:hover {
      background-color: ${AMZ_YELLOW_400};
      border-color: ${AMZ_YELLOW_400};
    }

    &:active:not([disabled]) {
      background-color: ${AMZ_YELLOW_600};
      border-color: #008296;
      box-shadow: 0 0 0 3px ${AMZ_FOCUS_RING}, inset 0 0 0 2px #fff;
    }

    &[disabled] {
      background-color: ${AMZ_YELLOW_200};
      border-color: ${AMZ_YELLOW_300};
      color: #6f7373;
    }

    ${({ ghost, secondary }) =>
      (ghost || secondary) &&
      css`
    background-color: #fff;
      border: 1px solid #d5d9d9;
      color: ${AMZ_BLACK};
      border-radius: 8px;
      box-shadow: 0 2px 5px 0 rgba(213,217,217,.5)
      line-height: 28px;
      transition: all 150ms ease-out;

      &:hover {
        background-color: #edfdff;
        border-color: #d5d9d9;
      }
      
      &:active:not([disabled]) {
        background-color: #edfdff;
        border-color: #008296;
        box-shadow: 0 0 0 3px #c8f3fa, inset 0 0 0 2px #fff;
      }
      
      &[disabled] {
        background-color: #fff;
        border-color: #d5d9d9;
        color: #6F7373;
      }
    `}
  }
`;
