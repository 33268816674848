import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ImageContainer,
  ModalContent,
  StyledModal,
} from './ExampleModal.styles';
import { imagesUrl } from '../../utils';
import { useTimeSpentMetric } from '../../hooks';

export enum ImageExample {
  Example1 = 'Example1',
  Example2 = 'Example2',
  Example3 = 'Example3',
}

interface ExampleModalProps {
  onClose: () => void;
  image?: ImageExample;
}

const Images = {
  [ImageExample.Example1]: imagesUrl.example1,
  [ImageExample.Example2]: imagesUrl.example2,
  [ImageExample.Example3]: imagesUrl.example3,
};

const ExampleModal: FunctionComponent<ExampleModalProps> = ({
  onClose,
  image,
}) => {
  const { t } = useTranslation('common');
  useTimeSpentMetric(`exampleModal-${image}`);

  return (
    <StyledModal modalTitle={t('exampleModal.title')} closeAction={onClose}>
      <ModalContent>
        {image && (
          <ImageContainer>
            <img src={Images[image]} alt={t('exampleModal.title')}></img>
          </ImageContainer>
        )}
      </ModalContent>
    </StyledModal>
  );
};

export default ExampleModal;
