import { SupportType } from '../api';
import { AppOutcome } from '../types';

export const getModelOutcome = (type: SupportType): AppOutcome => {
  switch (type) {
    case 'full_support':
      return AppOutcome.Compatible;
    case 'no_support':
      return AppOutcome.NotCompatible;
    case 'possible_support':
      return AppOutcome.InfoNeeded;
  }
};
