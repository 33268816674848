import styled from 'styled-components';
import { PRIMARY_200, PRIMARY_500, TERTIARY_500 } from '../../styles/colors';

export const StyledCard = styled.div`
  max-width: 435px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${TERTIARY_500};
  cursor: pointer;
  transition: 120ms border ease-in-out;

  &:active {
    border: 1px solid ${PRIMARY_500};
  }
`;

export const Content = styled.div`
  padding: 16px 24px;
`;

export const Image = styled.img`
  max-width: 100%;
  background-color: ${PRIMARY_200};
  width: 100%;
  object-fit: contain;
  height: 185px;
  border-radius: 4px 4px 0px 0px;

  @media (min-width: 768px) {
    height: 245px;
  }
`;
