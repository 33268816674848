import styled, { css } from 'styled-components';
import { BODY_NORMAL_LIGHT, BODY_NORMAL_MEDIUM } from '../../styles/typography';
import { isAmazonHost } from '../../utils';

export const ResultInfo = styled.p`
  ${BODY_NORMAL_LIGHT}

  ${() =>
    isAmazonHost() &&
    css`
      @media (max-width: 768px) {
        margin-bottom: 24px !important;
        font-size: 16px !important;
        line-height: 21px !important;

        & > *,
        & > a,
        & > span {
          font-size: 16px !important;
          line-height: 21px !important;
        }
      }
    `}
`;

export const Disclaimer = styled(ResultInfo)`
  ${() =>
  isAmazonHost() &&
  css`
      @media (max-width: 768px) {
        font-size: 14px !important;

        & > *,
        & > a,
        & > span {
          font-size: 14px !important;
        }
      }
    `}
`;

export const ModelBrand = styled.span`
  ${BODY_NORMAL_MEDIUM}
  margin-left: 4px;

  ${() =>
    isAmazonHost() &&
    css`
      @media (max-width: 768px) {
        font-size: 16px !important;
        line-height: 21px !important;
      }
    `}
`;
