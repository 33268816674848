import { TestId } from '../../utils';
import { Content, Image, StyledCard } from './Card.styles';

interface CardProps {
  image: string;
  imageAlt: string;
  content: React.ReactNode;
  onClick?: () => void;
}

const Card = ({ image, imageAlt, content, onClick }: CardProps) => {
  return (
    <StyledCard onClick={onClick} data-testid={TestId.Card}>
      <Image src={image} alt={imageAlt} />
      <Content>{content}</Content>
    </StyledCard>
  );
};

export default Card;
