import React, { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Result from '../../components/Result/Result';
import { Anchor, Question } from '../../App.styles';
import { Disclaimer, ModelBrand, ResultInfo } from '../../components/Result/Result.styles';
import { Button, NavFooter } from '../../components';
import { usePdpUrl, useTimeSpentMetric } from '../../hooks';
import InfoBox from '../../components/InfoBox/InfoBox';
import { closeAmzModal, formatCurrency, iconUrl, imagesUrl, isAmazonHost, TestId } from '../../utils';
import { FlowStep, GlobalContext, StepperContext, updateFlowStep } from '../../contexts';
import { environment } from '../../utils/environment';
import { PRIMARY_500 } from '../../styles/colors';
import { StepKey } from '../../types';
import { Treatment } from '../../weblab/treatment';

const Success: FunctionComponent = () => {
  const { dispatch } = React.useContext(GlobalContext);
  const { stepperData, updateStepperData, steps } = React.useContext(StepperContext);
  const { brand, model, variant, secondAttempt } = stepperData
    ? stepperData
    : { brand: undefined, model: undefined, variant: undefined, secondAttempt: undefined };
  const { t } = useTranslation('common');
  const pdpUrl = usePdpUrl();
  const { coupon, treatment } = environment ?? {};

  useTimeSpentMetric('success');

  return (
    <Result image={{source: imagesUrl.success, altText: t('images.success')}}>
      <div className="row h-100 flex-column" data-testid={TestId.Success}>
        <div className="flex-grow-1 d-flex flex-column">
          <div>
            <div className="mt-2 mt-md-4">
              <img src={iconUrl.check} alt="success"/>
            </div>

            <Question className="my-3">{t('results.success.title')}</Question>

            <ResultInfo>
              <span>{t('results.success.description')}</span>
              {brand && model ? (
                <span>
                :
                <ModelBrand>
                  {brand}, {model.model_name}.
                </ModelBrand>
              </span>
              ) : (
                <span>.</span>
              )}
            </ResultInfo>

            <ResultInfo>
              <span>{t(treatment === Treatment.V3_5 ? 'results.success.videoDisclaimer-v3_5' : 'results.success.videoDisclaimer')}</span>
            </ResultInfo>

            {variant?.ring_to_open && (
              <InfoBox
                title={t('results.success.r2oTitle')}
                description={t('results.success.r2oDescription')}
              />
            )}

            {treatment === Treatment.V3_5 && !secondAttempt &&
              <ResultInfo className="mx-0 fw-semibold">
                {t('results.success.multiDoor')} <Anchor
                              tabIndex={0}
                              role="button"
                              onClick={() => {
                                updateStepperData({
                                  activeStep: steps.findIndex(s => s === StepKey.IsParallelAfterSuccess),
                                  multiDoor: true,
                                  secondAttempt: true
                                });
                                dispatch(
                                  updateFlowStep({ flowStep: FlowStep.compatibilityChecker })
                                );
                              }}>
                {t('buttons.continue')}
              </Anchor>
              </ResultInfo>
            }
          </div>

          {coupon?.code && coupon?.amount && coupon?.currency && (
            <div className="flex-grow-1 d-flex flex-column justify-content-between mt-md-5 mt-sm-3">
              <div>
                <Question className="mx-md-5 mx-sm-0 text-center">
                  <Trans
                    i18nKey="common:results.success.coupon.congrats"
                    values={{
                      code: coupon.code,
                      amount: formatCurrency(coupon.amount, coupon.currency),
                    }}
                    components={{ couponCode: <span style={{color: PRIMARY_500}} /> }}
                  />
                </Question>
              </div>

              <div>
                <Disclaimer>
                  <Trans
                    i18nKey="common:results.success.coupon.disclaimer"
                    values={{
                      code: coupon.code
                    }}
                    components={{
                      tncLink: <Anchor tabIndex={0} target="_blank"
                                       href='/gp/help/customer/display.html?nodeId=G8LF2MC32ZASDWVU' role="button" />
                    }}
                  />
                </Disclaimer>
              </div>
            </div>
          )}
        </div>

        <NavFooter>
          {isAmazonHost() ? (
            <>
              <Button type="button" onClick={closeAmzModal}>
                {t('header.close')}
              </Button>
              <Button
                type="button"
                ghost
                onClick={() => {
                  dispatch(
                    updateFlowStep({ flowStep: FlowStep.compatibilityChecker }),
                  );
                }}
              >
                {t('header.back')}
              </Button>
            </>
          ) : (
            <a href={pdpUrl}>
              <Button type="button" className="w-100">
                {t('results.shopIntercom')}
              </Button>
            </a>
          )}
        </NavFooter>
      </div>
    </Result>
  );
};

export default Success;
