import {
  BackplateSelection,
  BrandSelection,
  CompatibilityOptions,
  HasBackplate,
  ModelLoadingEmpty,
  Models,
  ModelSelection,
  NamingConventions,
  PreQualificationQuestion,
  Step,
} from '../components';
import { AppOutcome, StepKey } from '../types';
import { ScreenOutcomeType } from '../hooks';
import { ModelInterface, ReasonOutcomeType } from '../hooks/useHandleOutcome';
import React from 'react';
import { StepperContextData } from '../contexts';
import { ModelVariant, NamingConvention, SchemePreference } from '../api';
import { TFunction } from 'react-i18next';
import { imagesUrl, metricEvents, MetricProperty } from '../utils';
import { Treatment } from './treatment';

const getSteps = (
  stepperData: StepperContextData,
  t: TFunction,
  actions: {
    handleStep: (customPos?: number, customData?: Partial<StepperContextData>) => void,
    handleBack: () => void,
    updateStepperData: (value: Partial<StepperContextData>) => void,
    track: (event: string, properties?: Record<string, unknown> | undefined) => void
    handleOutcome: ({
                      value,
                      screen,
                      brand,
                      model,
                      terminals,
                      variant,
                      reason,
                      searchText,
                    }: {
      value: AppOutcome;
      screen: ScreenOutcomeType;
      reason: ReasonOutcomeType;
      brand?: string;
      model?: ModelInterface;
      terminals?: NamingConvention[];
      variant?: ModelVariant;
      searchText?: string;
    }) => void
  } = {
    handleStep: () => void 0,
    handleBack: () => void 0,
    track: () => void 0,
    handleOutcome: () => void 0,
    updateStepperData: () => void 0,
  },
) => {
  const {
    hasIntercom,
    isParallel,
    searchBy,
    hasOneButton,
    multiDoor,
    activeStep,
    fallbackToModel,
    hasBackplate,
    brand,
  } = stepperData;

  const { handleStep, updateStepperData, handleOutcome, track, handleBack } = actions;

  const findModelSteps = [
    <Step
      key={StepKey.FindModel}
      stepKey={StepKey.FindModel}
      image={{ source: imagesUrl.find_model, altText: t('images.findModel') }}
      preferHuman
    >
      <ModelSelection
        next={() => {
          handleStep(activeStep + 1, { hasBackplate: true });
        }}
        onBack={handleBack}
      />
    </Step>,
    ...(hasBackplate
      ? [
        <Step
          key={StepKey.HasBackplate}
          stepKey={StepKey.HasBackplate}
          image={{
            source: imagesUrl.backplate,
            altText: t('images.backplate'),
          }}
          preferHuman
        >
          <HasBackplate
            next={() => handleStep(undefined, undefined)}
            onBack={handleBack}
          />
        </Step>,
        <Step
          key={StepKey.BackplateSelection}
          stepKey={StepKey.BackplateSelection}
          image={{
            source: imagesUrl.backplate,
            altText: t('images.backplate'),
          }}
          preferHuman
        >
          <BackplateSelection onBack={handleBack} />
        </Step>,
      ]
      : []),
  ];

  const compatibilityOptionsSteps = [
    <Step
      key={StepKey.FindBrand}
      stepKey={StepKey.FindBrand}
      image={{ source: imagesUrl.find_brand, altText: t('images.findBrand') }}
      preferHuman
    >
      <BrandSelection
        next={(shouldSkipOptions) => {
          // if no terminal labels, redirect straight to model selection
          const customPos = shouldSkipOptions ? activeStep + 2 : undefined;
          const customData = shouldSkipOptions
            ? { searchBy: SchemePreference.ModelName }
            : undefined;
          if (customPos !== undefined) {
            track(metricEvents.searchPreferenceSkipped, {
              [MetricProperty.searchPreference]: SchemePreference.ModelName,
              [MetricProperty.selectedBrand]: brand,
            });
          }
          handleStep(customPos, customData);
        }}
        onBack={handleBack}
      />
    </Step>,

    <Step
      key={StepKey.CompatibilityOptions}
      stepKey={StepKey.CompatibilityOptions}
    >
      <CompatibilityOptions
        onSelect={(val) => {
          track(metricEvents.searchPreference, {
            [MetricProperty.searchPreference]: val,
            [MetricProperty.selectedBrand]: brand,
          });
          handleStep(undefined, { searchBy: val });
        }}
      />
    </Step>,
    ...(searchBy === SchemePreference.ModelName
      ? [...findModelSteps]
      : [
        <Step
          key={StepKey.NamingConventions}
          stepKey={StepKey.NamingConventions}
          image={{
            source: imagesUrl.find_model,
            altText: t('images.findModel'),
          }}
          preferHuman
        >
          <NamingConventions
            next={(customData) => handleStep(undefined, customData)}
            onBack={handleBack}
          />
        </Step>,
        <Step
          key={StepKey.Models}
          stepKey={StepKey.Models}
          image={{
            source: imagesUrl.find_model,
            altText: t('images.findModel'),
          }}
          preferHuman
        >
          <Models
            next={(customData) => handleStep(undefined, customData)}
            onBack={handleBack}
          />
        </Step>,
        ...(fallbackToModel ? [...findModelSteps] : []),
      ]),
  ];

  const stepsV35 = [
    ...compatibilityOptionsSteps,
    <Step key={StepKey.IsParallelAfterSuccess} stepKey={StepKey.IsParallelAfterSuccess}>
      <PreQualificationQuestion
        question={t('prompts.isParallel')}
        value={isParallel}
        groupName="isParallel"
        yesAction={() => {
          updateStepperData({ isParallel: true });
          handleOutcome({
            value: AppOutcome.Compatible,
            screen: ScreenOutcomeType.Question,
            reason: ReasonOutcomeType.CompleteAfterSuccess,
          });
        }}
        noAction={() => handleStep(activeStep + 1, { isParallel: false })}
        yesText={t('responses.q4Yes')}
        noText={t('responses.q4No')}
      />
    </Step>,

    <Step key={StepKey.HasOneButton} stepKey={StepKey.HasOneButton}>
      <PreQualificationQuestion
        question={t('prompts.hasOneButton')}
        value={hasOneButton}
        groupName="hasOneButton"
        yesAction={() => handleStep(activeStep + 1, { hasOneButton: true })}
        noAction={() => {
          updateStepperData({ hasOneButton: false });
          handleOutcome({
            value: AppOutcome.NotCompatible,
            screen: ScreenOutcomeType.Question,
            reason: ReasonOutcomeType.MultiButtonAfterSuccess,
          });
        }}
        yesText={t('responses.q5Yes')}
        noText={t('responses.q5No')}
      />
    </Step>,
    <Step key={StepKey.ModelLoadingEmpty} stepKey={StepKey.ModelLoadingEmpty}>
      <ModelLoadingEmpty />
    </Step>
  ];

  const controlSteps = [
    <Step key={StepKey.HasIntercom} stepKey={StepKey.HasIntercom}>
      <PreQualificationQuestion
        question={t('prompts.hasIntercom')}
        value={hasIntercom}
        groupName="hasIntercom"
        yesAction={() => handleStep(activeStep + 1, { hasIntercom: true })}
        noAction={() => {
          updateStepperData({ hasIntercom: false });
          handleOutcome({
            value: AppOutcome.NotCompatible,
            screen: ScreenOutcomeType.Question,
            reason: ReasonOutcomeType.NoIntercom,
          });
        }}
      />
    </Step>,

    <Step key={StepKey.HasMultipleDoors} stepKey={StepKey.HasMultipleDoors}>
      <PreQualificationQuestion
        question={t('prompts.hasMultipleDoors')}
        value={multiDoor}
        groupName="hasMultipleDoors"
        yesAction={() => {
          handleStep(activeStep + 1, {
            multiDoor: true,
            isParallel: undefined,
          });
        }}
        noAction={() => {
          handleStep(activeStep + 3, { multiDoor: false, isParallel: false });
        }}
        yesText={t('responses.q3Yes')}
        noText={t('responses.q3No')}
      />
    </Step>,

    <Step key={StepKey.IsParallel} stepKey={StepKey.IsParallel}>
      <PreQualificationQuestion
        question={t('prompts.isParallel')}
        value={isParallel}
        groupName="isParallel"
        yesAction={() => handleStep(activeStep + 2, { isParallel: true })}
        noAction={() => handleStep(activeStep + 1, { isParallel: false })}
        yesText={t('responses.q4Yes')}
        noText={t('responses.q4No')}
      />
    </Step>,

    <Step key={StepKey.HasOneButton} stepKey={StepKey.HasOneButton}>
      <PreQualificationQuestion
        question={t('prompts.hasOneButton')}
        value={hasOneButton}
        groupName="hasOneButton"
        yesAction={() => handleStep(activeStep + 1, { hasOneButton: true })}
        noAction={() => {
          updateStepperData({ hasOneButton: false });
          handleOutcome({
            value: AppOutcome.NotCompatible,
            screen: ScreenOutcomeType.Question,
            reason: ReasonOutcomeType.MultiButton,
          });
        }}
        yesText={t('responses.q5Yes')}
        noText={t('responses.q5No')}
      />
    </Step>,
    ...compatibilityOptionsSteps
  ];

  const steps = {
    [Treatment.V3_5]: stepsV35,
  } as { [key: string]: React.JSX.Element[] };

  return (treatment: string): React.JSX.Element[] => steps[treatment] ?? controlSteps;
};

export default getSteps;
