import { isAmazonHost, logger } from '../utils';
import { useHeap } from './useHeap';
import { environment } from '../utils/environment';

const useMetrics = () => {
  const { track: trackHeap } = useHeap();

  const getAmzNavConfig = () => {
    let customerId = null;
    if (typeof window !== 'undefined' && isAmazonHost()) {
      const sessionStorageCid = JSON.parse(
        sessionStorage.getItem('amzn:fwcim:events') ?? '{}',
      )?.[0]?.item?.c;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const navConfig = (window as any)?.$Nav?.getNow?.('config');
      const navConfigCid = navConfig?.searchISS?.customerId
        ?? navConfig?.lightningDeals?.customerID
        ?? null;

      customerId = sessionStorageCid ?? navConfigCid;
    }
    return { customerId };
  };

  const track = (
    event: string,
    properties?: Record<string, unknown> | undefined
  ) => {
    if (isAmazonHost()) {
      const { customerId } = getAmzNavConfig();
      const { treatment } = environment;
      logger.info(event, { ...properties, customerId, treatment });
    } else {
      trackHeap(event, properties);
    }
  };

  return { track };
};

export default useMetrics;
