import { isAmazonHost } from '../../utils';
import { RadioInput, RadioLabel, RadioContainer } from './RadioButton.styles';

interface Props<T> {
  id: string;
  groupName: string;
  breakWord?: string;
  label: string;
  value: T;
  selectedValue: T;
  onChange?: () => void;
  onClick?: () => void;
}

const RadioButton = <T,>(props: Props<T>) => {
  const {
    id,
    groupName,
    label,
    breakWord = 'initial',
    value,
    selectedValue,
    onChange,
    onClick,
  } = props;

  return (
    <RadioContainer isAmazon={isAmazonHost()}>
      <RadioInput
        type="radio"
        checked={value === selectedValue}
        name={groupName}
        id={id}
        onChange={onChange}
        onClick={onClick}
        isAmazon={isAmazonHost()}
      />
      <RadioLabel htmlFor={id} breakWord={breakWord} isAmazon={isAmazonHost()}>
        {label}
      </RadioLabel>
    </RadioContainer>
  );
};

export default RadioButton;
