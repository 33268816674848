import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, NavFooter, Stepper } from '../';
import { AppOutcome, StepKey } from '../../types';
import { ScreenOutcomeType, useHandleOutcome, useMetrics } from '../../hooks';
import { BrandsContext, StepperContext, StepperContextData } from '../../contexts';
import { isAmazonHost, isEmpty, isNil, metricEvents, MetricProperty } from '../../utils';
import { ReasonOutcomeType } from '../../hooks/useHandleOutcome';
import { environment } from '../../utils/environment';
import getSteps from '../../weblab/stepsExperiments';

const Wizard: FunctionComponent = () => {
  const { t } = useTranslation('common');
  const { handleOutcome } = useHandleOutcome();
  const { stepperData, updateStepperData, updateSteps } = useContext(StepperContext);
  const { brands } = useContext(BrandsContext);
  const [localStepperData, setLocalStepperData] = useState<
    Partial<StepperContextData>
  >({});

  const { track } = useMetrics();
  const {
    isParallel,
    multiDoor,
    activeStep,
    brand,
    terminals,
    hasIntercom,
    searchBy,
    hasOneButton,
    fallbackToModel,
    hasBackplate,
    candidate,
    model
  } = stepperData;

  const stepWithFooter = () =>
    [
      StepKey.BackplateSelection,
      StepKey.FindBrand,
      StepKey.HasBackplate,
      StepKey.Models,
      StepKey.FindModel,
      StepKey.NamingConventions,
    ].includes(steps[stepperData.activeStep].key as StepKey);

  const handleStep = (
    customPos?: number,
    customData?: Partial<StepperContextData>
  ) => {
    const newStepperData = {
      ...customData,
      activeStep: customPos ?? activeStep + 1
    };

    updateStepperData({ ...newStepperData });
  };

  const handleBack = () => {
    if (activeStep <= 0) {
      return;
    }

    const step = steps[activeStep];
    let customPos = activeStep - 1;

    // skip hasMultiButton step if it was skipped when navigating forward
    if (step.key === StepKey.FindBrand) {
      if (!multiDoor) {
        customPos = activeStep - 3;
      }

      if (multiDoor && isParallel) {
        customPos = activeStep - 2;
      }
    }

    // skip compatibilityOptions step if skipped when navigating forward
    if (
      [StepKey.FindModel, StepKey.NamingConventions].includes(
        step.key as StepKey
      )
    ) {
      const selectedBrand = brands?.find((obj) => obj.brand_name === brand);
      if (isEmpty(selectedBrand?.terminal_labels)) {
        customPos = activeStep - 2;
      }
    }

    if (StepKey.IsParallelAfterSuccess === step.key &&
      terminals?.length &&
      !candidate &&
      !model) {
      customPos = activeStep - 2;
    }

    // clear data when navigate back
    const customData: StepperContextData = {
      ...stepperData,
      ...(step.key === StepKey.FindModel && {
        model: undefined,
      }),
      ...(step.key === StepKey.FindBrand && {
        brand: undefined,
      }),
      ...(step.key === StepKey.IsParallel && {
        isParallel: undefined,
      }),
      ...(step.key === StepKey.IsParallelAfterSuccess && {
        isParallel: undefined,
        multiDoor: undefined,
        secondAttempt: false
      }),
      ...(step.key === StepKey.HasMultipleDoors && {
        multiDoor: undefined,
        isParallel: undefined,
      }),
      ...(step.key === StepKey.CompatibilityOptions && {
        searchBy: undefined,
      }),
      ...(step.key === StepKey.HasOneButton && {
        hasOneButton: undefined,
      }),
      ...(step.key === StepKey.BackplateSelection && {
        variant: undefined,
      }),
      ...(step.key === StepKey.HasBackplate && {
        hasBackplate: undefined,
      }),
      ...(step.key === StepKey.Models && {
        candidate: undefined,
      }),
      ...(step.key === StepKey.NamingConventions && {
        terminals: undefined,
      })
    };

    updateStepperData({
      ...customData,
      activeStep: customPos,
    });
    if (isAmazonHost()) {
      setLocalStepperData({
        ...customData,
        activeStep,
      });
    }
  };

  const handleContinue = () => {
    if (stepperData.hasIntercom === false) {
      handleOutcome({
        value: AppOutcome.NotCompatible,
        screen: ScreenOutcomeType.Question,
        reason: ReasonOutcomeType.NoIntercom,
      });
    } else if (stepperData.hasOneButton === false) {
      handleOutcome({
        value: AppOutcome.NotCompatible,
        screen: ScreenOutcomeType.Question,
        reason: ReasonOutcomeType.MultiButton,
      });
    } else {
      updateStepperData({ ...localStepperData });
    }
  };

  const shouldDisableCTA = () => {
    const step = steps[activeStep];
    if (
      (step?.key === StepKey.HasIntercom && isNil(stepperData.hasIntercom)) ||
      (step?.key === StepKey.HasMultipleDoors &&
        isNil(stepperData.multiDoor)) ||
      (step?.key === StepKey.IsParallel && isNil(stepperData.isParallel)) ||
      (step?.key === StepKey.HasOneButton && isNil(stepperData.hasOneButton))
    ) {
      return true;
    }
    return false;
  };

  const steps = getSteps(stepperData, t, {
    handleStep, handleBack, handleOutcome, track, updateStepperData,
  })(environment.treatment as string);

  useEffect(() => {
    const step = steps[activeStep];
    track(metricEvents.pageView, {
      [MetricProperty.pageName]: step.key,
    });
  }, [activeStep]);

  useEffect(() => {
    updateSteps(steps.map(s => s.key) as string[]);
  }, [hasIntercom,
    isParallel,
    searchBy,
    hasOneButton,
    multiDoor,
    activeStep,
    fallbackToModel,
    hasBackplate,
    brand,]);

  return (
    <>
      <Stepper
        headerTitle={t('header.title')}
        hasBackButton={activeStep !== 0}
        activeStep={activeStep}
        showStepIndicator
        onBack={handleBack}
      >
        {steps}
      </Stepper>
      {isAmazonHost() && !stepWithFooter() && (
        <NavFooter xPadding={36}>
          {![StepKey.CompatibilityOptions].includes(
            steps[stepperData.activeStep].key as StepKey
          ) && (
            <Button
              type="button"
              onClick={handleContinue}
              disabled={shouldDisableCTA()}
            >
              {t('buttons.continue')}
            </Button>
          )}
          {![StepKey.HasIntercom].includes(
            steps[stepperData.activeStep].key as StepKey
          ) && (
            <Button type="button" secondary onClick={handleBack}>
              {t('header.back')}
            </Button>
          )}
        </NavFooter>
      )}
    </>
  );
};

export default Wizard;
