import { FunctionComponent, useRef } from 'react';
import { Wizard } from '../../components';
import { SessionContext } from '../../contexts';

const CompatibilityChecker: FunctionComponent = () => {
  const sessionInitTime = useRef(new Date());

  return (
    <SessionContext.Provider value={sessionInitTime.current}>
      <Wizard />
    </SessionContext.Provider>
  );
};

export default CompatibilityChecker;
