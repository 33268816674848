import styled, { css } from 'styled-components';
import { BODY_NORMAL_REGULAR } from '../../styles/typography';
import { WHITE } from '../../styles/colors';

interface HeaderContainerProps {
  isAmazon?: boolean;
}

export const HeaderContainer = styled.div<HeaderContainerProps>`
  position: fixed;
  ${BODY_NORMAL_REGULAR}
  width: 100%;
  background-color: ${WHITE};
  z-index: 10;

  ${({ isAmazon }) =>
    !isAmazon &&
    css`
      height: 60px;
    `}
`;

export const TitleContainer = styled.div`
  height: 60px;
`;

export const Title = styled.p`
  &&& {
    font-size: 1rem;
    margin-bottom: 0;
  }
`;

export const BackButton = styled.button`
  width: auto;
  max-width: 100px !important;
  position: absolute;
  left: 0;
  display: flex !important;
  align-items: center;

  span {
    margin-top: 2px;
    margin-left: 5px;
  }
`;

export const ExitButton = styled.button`
  width: auto;
  position: absolute;
  right: 0;
`;
