import { useEffect, useState } from 'react';
import { config } from '../config';
import { getLanguage, isAmazonHost } from '../utils';

const { CONSENT_MANAGER_SCRIPT_URL } = config;

const CM_SCRIPT_TAG_ID = 'consent-script';
const CM_BANNER_BUTTON_ACCEPT_CLASSNAME = 'consent--accept';

export type LocaleConsentManagerType = 'en-EU' | 'en-US';

export const useConsentManager = () => {
  const [isTrackingAccepted, setIsTrackingAccepted] = useState(false);

  useEffect(() => {
    // do not show consent banner for a.com
    if (isAmazonHost()) {
      return;
    }

    if (document.getElementById(CM_SCRIPT_TAG_ID)) {
      return;
    }
    let locale = getLanguage();
    if (locale && locale.length === 2) {
      locale = `${locale}-${locale.toUpperCase()}`;
    }
    const localeSettings =
      locale === 'en-US'
        ? `locale=en-US&loggedIn=0`
        : `locale=${locale}&loggedIn=0&region=EU`;

    const script = document.createElement('script');

    script.id = CM_SCRIPT_TAG_ID;
    script.type = 'text/javascript';
    script.src = `${CONSENT_MANAGER_SCRIPT_URL}?${localeSettings}`;
    script.async = true;

    document.head.appendChild(script);

    const handleClickAccept = ({ target }: MouseEvent) => {
      if (
        target instanceof HTMLElement &&
        target.className.includes(CM_BANNER_BUTTON_ACCEPT_CLASSNAME)
      ) {
        setIsTrackingAccepted(true);
      }
    };

    document.addEventListener('click', handleClickAccept);

    return () => {
      document.removeEventListener('click', handleClickAccept);
    };
  }, [setIsTrackingAccepted]);

  return isTrackingAccepted;
};
