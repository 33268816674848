import styled from 'styled-components';
import Modal from '../Modal/Modal';

export const StyledModal = styled(Modal)`
  @media (min-width: 768px) {
    /* max-height: 400px; */
  }
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  margin-top: 0px;
  margin-bottom: 24px;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;

  > img {
    max-width: 375px;
    height: auto;
    width: 100%;
    object-fit: contain;
    border-radius: 8px;
  }
`;

export const ModalContent = styled.div`
  @media (min-width: 768px) {
    padding: 0px 16px 24px 16px;
  }
`;
