import React, { useReducer, createContext } from 'react';

import globalReducer from './GlobalContextReducer';
import { GlobalContextAction } from './GlobalContextActions';
import { defaultGlobalStore, GlobalStore } from './GlobalContextTypes';

export type GlobalContextT = {
  state: GlobalStore;
  dispatch: React.Dispatch<GlobalContextAction>;
};

export const GlobalContext = createContext<GlobalContextT>(
  {} as GlobalContextT
);

interface GlobalStateProps {
  defaultStore?: GlobalStore;
  children?: React.ReactNode;
}

export const GlobalState: React.FC<GlobalStateProps> = ({
  defaultStore,
  children,
}) => {
  const initialState = defaultStore ?? defaultGlobalStore;
  const [state, dispatch] = useReducer(globalReducer, initialState);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};
