import styled, { css } from 'styled-components';
import { PRIMARY_600, TERTIARY_500 } from '../../styles/colors';

interface StepIndicatorProps {
  progress?: number;
  isAmazon?: boolean;
}

export const StepIndicator = styled.div<StepIndicatorProps>`
  position: relative;
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
  height: 6px;
  border: 0;
  border-radius: ${({ isAmazon }) => (isAmazon ? 'initial' : '3px')};
  background-color: ${TERTIARY_500};

  &::after {
    content: '';
    border-radius: inherit;
    display: block;
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background-color: ${({ isAmazon }) => (isAmazon ? '#067d62' : PRIMARY_600)};
    transform: translateX(${({ progress }) => (progress ?? 0) * 100}%);
    transition: transform 0.2s ease-in-out;
  }
`;

export const BottomBorder = styled.div`
  height: 6px;
  border-bottom: 1px solid ${TERTIARY_500};
`;

interface ActiveStepProps {
  isAmazon?: boolean;
}

export const ActiveStep = styled.div<ActiveStepProps>`
  height: 100%;

  ${({ isAmazon }) =>
    !isAmazon &&
    css`
      padding-top: 66px;
    `}
`;
