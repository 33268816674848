import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCookie, getRedirectUrl } from '../utils';

export function usePdpUrl() {
  const [pdpUrl, setPdpUrl] = useState('');
  const { t } = useTranslation('common');
  const homeUrl = useRef(t('results.homeUrl'));

  useEffect(() => {
    const redirectUrl = getRedirectUrl();

    if (redirectUrl) {
      setPdpUrl(redirectUrl.url);
    } else {
      const returnPath = getCookie('cct_return-path') || 'intercom';
      setPdpUrl(`${homeUrl.current}products/${returnPath}`);
    }
  });

  return pdpUrl;
}
