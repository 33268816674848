import React from 'react';
import { FunctionComponent } from 'react';

import Modal from '../Modal/Modal';
import { Question } from '../../App.styles';
import { useTranslation } from 'react-i18next';
import { Card, List, ListItemTitle, MethodImage } from './ShowMeModal.styles';
import { imagesUrl } from '../../utils';
import { useTimeSpentMetric } from '../../hooks';

interface Props {
  closeModal: () => void;
}

interface Method {
  image: string;
  name: string;
  instructions: string[];
}

const ShowMeModal: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation('common');
  const { closeModal } = props;

  useTimeSpentMetric('showMeModal');

  const methods: Method[] = [
    {
      image: imagesUrl.screw,
      name: t('removalModal.screwMethod.title'),
      instructions: [
        t('removalModal.screwMethod.step1'),
        t('removalModal.screwMethod.step2'),
        t('removalModal.screwMethod.step3'),
      ],
    },
    {
      image: imagesUrl.pull_off,
      name: t('removalModal.pullOffMethod.title'),
      instructions: [
        t('removalModal.pullOffMethod.step1'),
        t('removalModal.pullOffMethod.step2'),
      ],
    },
    {
      image: imagesUrl.hidden_tab,
      name: t('removalModal.hiddenTabMethod.title'),
      instructions: [
        t('removalModal.hiddenTabMethod.step1'),
        t('removalModal.hiddenTabMethod.step2'),
      ],
    },
  ];

  return (
    <Modal modalTitle={t('removalModal.title')} closeAction={closeModal}>
      <div className="row">
        <Question className="col-12">{t('removalModal.instructions')}</Question>

        <div className="col-12">
          {methods.map((method) => (
            <Card key={method.name} className="p-3 my-3">
              <MethodImage src={method.image} alt="method"></MethodImage>
              <div className="px-3 mt-3 mt-md-0">
                <ListItemTitle>{method.name}</ListItemTitle>
                <List className="mt-2">
                  {method.instructions.map((instruction) => (
                    <li key={instruction}>{instruction}</li>
                  ))}
                </List>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default ShowMeModal;
