import styled, { css } from 'styled-components';
import { PRIMARY_100, TERTIARY_300 } from '../../styles/colors';
import { BODY_NORMAL_LIGHT } from '../../styles/typography';
import { isAmazonHost } from '../../utils';

interface InfoBoxProps {
  isHelp?: boolean;
}

export const InfoBoxRoot = styled.div<InfoBoxProps>`
  background-color: ${TERTIARY_300};

  ${() =>
    isAmazonHost() &&
    css`
      @media (max-width: 768px) {
        margin-bottom: 10px;
      }
    `}

  .info-description {
    ${BODY_NORMAL_LIGHT};

    ${() =>
      isAmazonHost() &&
      css`
        font-weight: 400;

        @media (max-width: 768px) {
          font-size: 14px !important;
          line-height: 20px !important;
        }
      `}
  }

  ${(props) =>
    props.isHelp &&
    css`
      background-color: ${PRIMARY_100};
    `}

  ${() =>
    isAmazonHost() &&
    css`
      background-color: rgb(247, 254, 255);
      border: 1px solid rgb(61, 183, 204);
      border-radius: 8px;
    `}
`;

export const InfoTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #0f1111 !important;
  font-weight: 700;

  ${() =>
    isAmazonHost() &&
    css`
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 5px;
      }
    `}
`;
