import styled from 'styled-components';
import { WHITE } from '../../styles/colors';

export const Footer = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: ${WHITE};
  z-index: 20;

  & > * {
    background-color: ${WHITE};
  }
`;

interface ButtonsContainerProps {
  isAmazon?: boolean;
  xPadding?: number;
}

export const ButtonsContainer = styled.div<ButtonsContainerProps>`
  display: flex;
  flex-direction: row-reverse;
  justify-content: end;
  align-items: center;
  padding: 8px 24px;
  padding: ${({ xPadding }) => `8px ${xPadding ?? 24}px`};

  & > *:not(:first-child) {
    margin-right: 10px;
  }
`;
