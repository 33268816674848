import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, HashRouter } from 'react-router-dom';

import App from './App';
import { GlobalState } from './contexts/GlobalContext';
import { environment } from './utils/environment';
import { isAmazonHost } from './utils';
import { handleAmazonInitialRender } from './utils/handleAmazonInitialRender';

const root = ReactDOM.createRoot(document.getElementById('root'));

const getContent = () => (
  <GlobalState>
    <App />
  </GlobalState>
);

root.render(
  isAmazonHost() ? (
    <HashRouter>{getContent()}</HashRouter>
  ) : (
    <BrowserRouter basename={environment.basename}>
      {getContent()}
    </BrowserRouter>
  )
);

handleAmazonInitialRender();
