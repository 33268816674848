import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import './bootstrap-styles.scss';
import { useMetrics, useThirdPartyAnalytics } from './hooks';
import {
  CompatibilityChecker,
  Success,
  NotCompatible,
  InfoNeeded,
} from './pages';
import { AppStyled } from './App.styles';
import './style.css';
import './style-amz.css';
import { AppOutcome } from './types';
import {
  BrandsProvider,
  CandidateModelsProvider,
  StepperProvider,
} from './contexts';
import { FlowStep, GlobalContext } from './contexts/GlobalContext';
import {
  isAmazonHost,
  getUserPlatform,
  metricEvents,
  getBrowserName,
} from './utils';

const App = () => {
  useThirdPartyAnalytics();
  const { state: globalState } = useContext(GlobalContext);
  const { track } = useMetrics();

  useEffect(() => {
    track(metricEvents.appLoad, {
      userPlatform: getUserPlatform(),
      isAmazonHost: isAmazonHost(),
      browserName: getBrowserName(),
    });
  }, []);

  useLayoutEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    appHeight();

    window.addEventListener('resize', appHeight);
    return () => {
      window.removeEventListener('resize', appHeight);
    };
  }, []);

  const renderView = () => {
    switch (globalState.flowStep) {
      case FlowStep.compatibilityChecker:
        return <CompatibilityChecker />;
      case FlowStep.notCompatible:
        return <NotCompatible />;
      case FlowStep.success:
        return <Success />;
      case FlowStep.infoNeeded:
        return <InfoNeeded />;
    }
  };

  return (
    <I18nextProvider i18n={i18n}>
      <AppStyled className="container-fluid h-100" isAmazon={isAmazonHost()}>
        <StepperProvider>
          <BrandsProvider>
            <CandidateModelsProvider>
              {isAmazonHost() ? (
                renderView()
              ) : (
                <Routes>
                  <Route path="/" element={<CompatibilityChecker />} />
                  <Route
                    path={AppOutcome.NotCompatible}
                    element={<NotCompatible />}
                  />
                  <Route path={AppOutcome.Compatible} element={<Success />} />
                  <Route
                    path={AppOutcome.InfoNeeded}
                    element={<InfoNeeded />}
                  />
                </Routes>
              )}
            </CandidateModelsProvider>
          </BrandsProvider>
        </StepperProvider>
      </AppStyled>
    </I18nextProvider>
  );
};

export default App;
