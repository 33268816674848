import Fuse from 'fuse.js';
import { environment } from './environment';

export const getSearchResult = <T extends object>(
  list: Array<T>,
  key: keyof T,
  filter: string
) => {
  if (!filter.length) {
    return list;
  }
  const simpleSearch = list.filter((item) =>
    (item[key] as string).toLowerCase().includes(filter.toLowerCase().trim())
  );
  const fuse = new Fuse(list, { keys: [key as string], includeScore: true });

  const fuseSearch = fuse
    .search(filter)
    .filter((element) => {
      if (element.score) {
        return element.score <= (environment.fuzzyScoreLimit || 0.5);
      }
      return true;
    })
    .map((element) => element.item);

  return Array.from(new Set(simpleSearch.concat(fuseSearch)));
};
