import { isMshop } from './isAmazonHost';

export enum UserPlatform {
  MShop = 'mshop',
  DesktopBrowser = 'desktop_browser',
  MobileBrowser = 'mobile_browser',
}

export enum BrowserName {
  Firefox = 'Firefox',
  Chrome = 'Chrome',
  Safari = 'Safari',
  Edge = 'Edge',
  Opera = 'Opera',
  InternetExplorer = 'Internet Explorer',
  Unknown = 'Unknown',
}

export const getUserPlatform = () => {
  if (isMshop()) {
    return UserPlatform.MShop;
  } else {
    const userAgent = window.navigator.userAgent;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    ) {
      return UserPlatform.MobileBrowser;
    } else {
      return UserPlatform.DesktopBrowser;
    }
  }
};

export const getBrowserName = () => {
  const userAgent = window.navigator.userAgent;

  if (/firefox|fxios/i.test(userAgent)) {
    return BrowserName.Firefox;
  } else if (/chrome|crios|crmo/i.test(userAgent) && !/edg/i.test(userAgent)) {
    return BrowserName.Chrome;
  } else if (
    /safari/i.test(userAgent) &&
    !/chrome|crios|crmo/i.test(userAgent)
  ) {
    return BrowserName.Safari;
  } else if (/edg/i.test(userAgent)) {
    return BrowserName.Edge;
  } else if (/opr\//i.test(userAgent)) {
    return BrowserName.Opera;
  } else if (/msie|trident/i.test(userAgent)) {
    return BrowserName.InternetExplorer;
  } else {
    return BrowserName.Unknown;
  }
};
