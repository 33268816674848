import React from 'react';
import RadioButton from '../RadioButton/RadioButton';
import { Container } from './RadioGroup.styles';

export interface RadioOption<T> {
  id: string;
  label: string;
  value: T;
  onClick?: () => void;
}

interface Props<T> {
  options: RadioOption<T>[];
  groupName: string;
  breakWord?: string;
  value: T;
  setValue?: (value: T) => void;
  onChange?: (value: T) => void;
}

const RadioGroup = <T,>(props: Props<T>) => {
  const { options, groupName, breakWord, value, setValue, onChange } = props;

  return (
    <Container className="mb-3">
      {options.map((option) => (
        <RadioButton
          {...option}
          key={option.id}
          groupName={groupName}
          breakWord={breakWord}
          selectedValue={value}
          onChange={() => {
            setValue?.(option.value);
            onChange?.(option.value);
          }}
        />
      ))}
    </Container>
  );
};

export default RadioGroup;
