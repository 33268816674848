import { environment } from './environment';
import { getRedirectUrl } from './redirectUrl';

const setCookie = (cname: string, cvalue: string, exdays: number) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  document.cookie = `${cname}=${encodeURIComponent(cvalue)};path=/`;
};

// use next line to change locale with cookie
// setCookie('chosen_country', 'ES', 0);

const getCookie = (cname: string) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const getLanguage = () => {
  const locale = environment.locale;
  if (locale) {
    // if we get "es_US", only set as "es"
    return locale.split('_')[0];
  }

  const redirectUrl = getRedirectUrl();

  if (redirectUrl) {
    return redirectUrl.locale;
  }

  const cookieLanguage = getCookie('chosen_country').toLowerCase();

  if (cookieLanguage) {
    if (cookieLanguage === 'gb' || cookieLanguage === 'ie') {
      return 'en';
    } else if (cookieLanguage === 'be') {
      return 'nl';
    }
    return cookieLanguage;
  }

  return navigator.languages?.[0];
};

export { setCookie, getCookie, getLanguage };
