import { isDev, isQA } from '../utils';

import { beta } from './config.beta';
import { gamma } from './config.gamma';
import { prod } from './config.prod';
import { Config } from './types';

const getConfig = (): Config => {
  if (isDev) {
    return beta;
  }

  if (isQA) {
    return gamma;
  }

  return prod;
};

export const config = getConfig();
