import styled, { css } from 'styled-components';
import { NEUTRAL_300, TERTIARY_500 } from '../../styles/colors';
import { BODY_NORMAL_REGULAR } from '../../styles/typography';
import { isAmazonHost } from '../../utils';

export const Card = styled.div`
  border: 1px solid ${TERTIARY_500};
  border-radius: 8px;
`;

export const ListItemTitle = styled.p`
  ${() =>
    isAmazonHost() &&
    css`
      @media (max-width: 768px) {
        font-size: 17px;
        line-height: 24px;
      }
    `}
`;

export const Description = styled.span`
  color: ${NEUTRAL_300};
  ${BODY_NORMAL_REGULAR};

  ${() =>
    isAmazonHost() &&
    css`
      @media (max-width: 768px) {
        font-size: 15px !important;
        line-height: 20x !important;
      }
    `}
`;
