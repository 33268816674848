import { GlobalContextAction } from './GlobalContextActions';
import { GlobalActionType, GlobalStore } from './GlobalContextTypes';

const globalReducer = (
  state: GlobalStore,
  action: GlobalContextAction
): GlobalStore => {
  switch (action.type) {
    case GlobalActionType.UpdateFlowStep: {
      return {
        ...state,
        flowStep: action.payload.flowStep,
      };
    }
    default:
      return state;
  }
};

export default globalReducer;
