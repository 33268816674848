import React from 'react';
import { useTranslation } from 'react-i18next';

import { imagesUrl } from '../../utils';
import { Question } from '../../App.styles';
import { Modal } from '../';
import { Card, Description, ListItemTitle } from './PhotosModal.styles';
import { useTimeSpentMetric } from '../../hooks';

interface PhotosModalProps {
  closeModal: () => void;
}

interface RequiredPhoto {
  image: string;
  title: string;
  description: string;
}

const PhotosModal: React.FC<PhotosModalProps> = (props) => {
  const { closeModal } = props;
  const { t } = useTranslation('common');

  useTimeSpentMetric('photosModal');

  const requiredPhotos: RequiredPhoto[] = [
    {
      image: imagesUrl.exterior,
      title: t('photosModal.exterior.title'),
      description: t('photosModal.exterior.description'),
    },
    {
      image: imagesUrl.coverRemoved,
      title: t('photosModal.coverRemoved.title'),
      description: t('photosModal.coverRemoved.description'),
    },
    {
      image: imagesUrl.handsetOff,
      title: t('photosModal.handsetOff.title'),
      description: t('photosModal.handsetOff.description'),
    },
  ];

  return (
    <Modal modalTitle={t('photosModal.title')} closeAction={closeModal}>
      <div className="row">
        <Question className="col-12">{t('photosModal.instructions')}</Question>

        <div className="col-12">
          {requiredPhotos.map((photo) => (
            <Card key={photo.title} className="p-3 my-3">
              <div className="row">
                <div className="col-12 col-md-3">
                  <img src={photo.image} alt="card" />
                </div>
                <div className="col-12 col-md-9 mt-3 mt-md-0">
                  <div className="row">
                    <ListItemTitle className="col-12">
                      {photo.title}
                    </ListItemTitle>
                    <div className="col-12">
                      <Description>{photo.description}</Description>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default PhotosModal;
