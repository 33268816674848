import { useEffect, useRef } from 'react';
import useMetrics from './useMetrics';
import { isNil, metricEvents } from '../utils';

const formatMs = (ms: number) => {
  let seconds = ms / 1000;
  seconds = Math.round(seconds);
  const hours = Math.floor(seconds / 3600);
  seconds = seconds % 3600;
  const mins = Math.floor(seconds / 60);
  seconds = seconds % 60;

  if (hours > 0) return `${hours}h ${mins}m ${seconds}s`;
  else if (mins > 0) return `${mins}m ${seconds}s`;
  return `${seconds}s`;
};

const useTimeSpentMetric = (screenName: string) => {
  const { track } = useMetrics();
  const startTimeRef = useRef<number>(0);
  const alreadyTrackedRef = useRef<boolean>(false);

  const startTimer = (startTimeOverride?: number) => {
    startTimeRef.current = startTimeOverride ?? Date.now();
  };

  const stopTimerAndRecordTimerMetrics = () => {
    if (alreadyTrackedRef.current) return;
    if (isNaN(startTimeRef.current) || startTimeRef.current === 0) {
      throw new Error(
        `Invalid timer. Missing valid start time or override. page: ${screenName}`
      );
    }

    const now = Date.now();
    if (!isNil(screenName)) {
      track(metricEvents.timeSpent, {
        screenName,
        timeSpent: now - startTimeRef.current,
        timeSpentFriendly: formatMs(now - startTimeRef.current),
      });
      alreadyTrackedRef.current = true;
    }
    startTimeRef.current = now;
  };

  useEffect(() => {
    startTimer();
    window.addEventListener('beforeunload', stopTimerAndRecordTimerMetrics);
    return () => {
      stopTimerAndRecordTimerMetrics();
      window.removeEventListener(
        'beforeunload',
        stopTimerAndRecordTimerMetrics
      );
    };
  }, []);
};

export default useTimeSpentMetric;
