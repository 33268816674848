import React, { createContext, useState } from 'react';
import { Candidate, Model, ModelVariant, NamingConvention, SchemePreference } from '../api';

export interface StepperContextData {
  activeStep: number;
  multiDoor?: boolean;
  isParallel?: boolean;
  brand?: string;
  model?: Model;
  hasIntercom?: boolean;
  hasOneButton?: boolean;
  searchBy?: SchemePreference;
  terminals?: NamingConvention[];
  fallbackToModel?: boolean;
  hasBackplate?: boolean;
  variant?: ModelVariant;
  secondAttempt?: boolean;
  candidate?: Candidate;
}

interface StepperProviderProps {
  children?: React.ReactNode;
}

interface StepperContextProps {
  stepperData: StepperContextData;
  updateStepperData: (value: Partial<StepperContextData>) => void;
  steps: string[]
  updateSteps: (steps: string[]) => void;
}

export const StepperContext = createContext<StepperContextProps>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateStepperData: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateSteps: () => {},
  stepperData: { activeStep: 0 },
  steps: []
});

export const StepperProvider = ({ children }: StepperProviderProps) => {
  const [stepperData, setStepperData] = useState<StepperContextData>({
    activeStep: 0,
  });
  const [steps, setSteps] = useState<string[]>([]);

  const updateStepperData: StepperContextProps['updateStepperData'] = (
    partial
  ) => {
    setStepperData(prevStepperData => ({ ...prevStepperData, ...partial }));
  };

  const updateSteps = (steps: string[]) => setSteps([...steps]);

  return (
    <StepperContext.Provider value={{ stepperData, steps, updateStepperData, updateSteps }}>
      {children}
    </StepperContext.Provider>
  );
};
