import styled, { css } from 'styled-components';
import {
  PRIMARY_100,
  PRIMARY_200,
  PRIMARY_500,
  PRIMARY_600,
  PRIMARY_700,
  WHITE,
} from '../../styles/colors';
import { BODY_LARGE_BOLD } from '../../styles/typography';

interface ButtonProps {
  ghost?: boolean;
  secondary?: boolean;
}

export const Button = styled.button<ButtonProps>`
  &&& {
    color: ${WHITE};
    background-color: ${PRIMARY_500};
    border: none;
    border-radius: 8px;
    padding: 10px;
    transition: 120ms border ease-in-out;
    ${BODY_LARGE_BOLD}

    &:hover {
      background-color: ${PRIMARY_600};
    }

    &:active {
      background-color: ${PRIMARY_700};
    }

    &[disabled] {
      background-color: ${PRIMARY_200};
    }

    ${({ ghost, secondary }) =>
      (ghost || secondary) &&
      css`
        background-color: transparent;
        color: ${PRIMARY_500};

        &:hover {
          color: ${PRIMARY_600};
          background-color: transparent;
        }

        &:active {
          color: ${PRIMARY_700};
          background-color: ${PRIMARY_100};
        }

        &[disabled] {
          color: ${PRIMARY_200};
          background-color: transparent;
        }
      `}

    ${({ secondary }) =>
      secondary &&
      css`
        border: 1px solid ${PRIMARY_500};
        padding: 10px 16px;
        line-height: 28px;
        &:hover {
          border-color: ${PRIMARY_600};
        }

        &:active {
          border-color: ${PRIMARY_700};
        }

        &[disabled] {
          border-color: ${PRIMARY_200};
        }
      `}
  }
`;
