import styled, { css } from 'styled-components';
import { TERTIARY_500, PRIMARY_500, PRIMARY_100 } from '../../styles/colors';

export const Container = styled.div<{ active?: boolean; isAmazon?: boolean }>`
  position: relative;
  border-radius: 4px;
  border: 1px solid ${TERTIARY_500};
  padding: 16px;
  display: flex;

  ${({ isAmazon, active }) => {
    if (isAmazon) {
      return css`
        border-radius: 8px;

        &:hover {
          background-color: #f7fafa;
        }
        ${active
          ? `
          background-color: #EDFDFF;
          box-shadow: inset 0 0 0 3px #007185;
          border-color: #007185;
        `
          : `
          border-color: #888c8c;
        `}
      `;
    } else if (active) {
      return css`
        border-radius: 4px;
        border: 2px solid ${PRIMARY_500};
        background: ${PRIMARY_100};
      `;
    }
  }}
`;

export const Label = styled.label<{
  isIcon?: boolean;
  isAmazon?: boolean;
  active?: boolean;
}>`
  margin-left: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  ${({ isIcon }) =>
    isIcon &&
    css`
      font-size: 28px;
    `}

  ${({ isAmazon, active }) =>
    isAmazon &&
    css`
      width: 100%;
      text-align: center;
      ${active && `font-weight: 700;`}
    `}
  }
`;

export const Input = styled.input`
  z-index: 1;
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  opacity: 0;
  margin: 0px;
  padding: 0px;
  border: 0px;
  cursor: pointer;
  bottom: 0px !important;
  left: 0px;
`;
