import { useContext, useEffect, useState } from 'react';
import { BrandsContext, StepperContext } from '../contexts';
import { Brand } from '../api';

const useSelectedBrand = () => {
  const { brands } = useContext(BrandsContext);
  const { stepperData } = useContext(StepperContext);
  const { brand } = stepperData;
  const [selectedBrand, setSelectedBrand] = useState<Brand>();

  useEffect(() => {
    const currentBrand =
      brands && brand
        ? brands?.find((item) => item.brand_name === brand)
        : undefined;
    setSelectedBrand(currentBrand);
  }, [brands, brand]);

  return selectedBrand;
};
export default useSelectedBrand;
