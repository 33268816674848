import styled, { css } from 'styled-components';
import {
  NEGATIVE_500,
  NEUTRAL_500,
  PRIMARY_200,
  PRIMARY_500,
  PRIMARY_600,
  PRIMARY_700,
  TERTIARY_700,
} from './styles/colors';
import { Equip } from './styles/font';
import {
  BODY_NORMAL_REGULAR,
  BODY_SMALL_REGULAR,
  FONT_WEIGHT_REGULAR,
  HEADING_NORMAL_MEDIUM,
} from './styles/typography';
import { isAmazonHost } from './utils';

export const Question = styled.div`
  &&& {
    ${HEADING_NORMAL_MEDIUM};
    ${FONT_WEIGHT_REGULAR};

    ${() =>
      isAmazonHost() &&
      css`
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        color: #0f1111;

        @media (max-width: 768px) {
          margin-bottom: 16px !important;
        }
      `}
  }
`;

export const AppStyled = styled.div<{ isAmazon?: boolean }>`
  &&& {
    color: ${NEUTRAL_500};

    ${({ isAmazon }) =>
      isAmazon
        ? css`
            font-family: 'Amazon Ember', Arial, sans-serif !important;
          `
        : css`
            ${Equip}
            font-family: Equip,Helvetica,Arial,sans-serif !important;
          `}
  }
`;

interface AnchorProps {
  noUnderline?: boolean;
}

export const Anchor = styled.a<AnchorProps>`
  &&& {
    text-decoration: underline;
    color: ${PRIMARY_500};
    ${BODY_NORMAL_REGULAR};

    ${() =>
      isAmazonHost() &&
      css`
        @media (max-width: 768px) {
          font-size: 14px !important;
          line-height: 17px !important;
        }
      `}

    &:hover {
      color: ${PRIMARY_600};
    }

    &:active {
      color: ${PRIMARY_700};
    }

    &[disabled] {
      color: ${PRIMARY_200};
    }

    ${({ noUnderline }) =>
      noUnderline &&
      css`
        text-decoration: none;
      `}

    ${() =>
      isAmazonHost() &&
      css`
        color: #007185;
      `}
  }
`;

interface InputProps {
  hasError?: boolean;
}

export const Input = styled.input<InputProps>`
  &&& {
    height: 48px;
    border-color: ${TERTIARY_700};

    &:focus {
      border: 2px solid ${PRIMARY_500};
      box-shadow: none;
    }

    ${(props) =>
      props.hasError &&
      css`
        &,
        &:focus {
          border-color: ${NEGATIVE_500};
        }
      `}

    ${() =>
      isAmazonHost() &&
      css`
        background-color: #fff;
        height: 31px;
        padding: 3px 7px;
        line-height: normal;
        border: 1px solid #888c8c;
        border-radius: 4px;
        box-shadow: 0 1px 2px rgba(15, 17, 17, 0.15) inset;
        outline: 0;

        @media (max-width: 768px) {
          height: 36px;
          font-size: 16px !important;
          line-height: 22px !important;
        }

        &:focus {
          background-color: #f7feff !important;
          border-color: #007185 !important;
          box-shadow: 0 0 0 3px #c8f3fa, 0 1px 2px rgba(15, 17, 17, 0.15) inset !important;
          border-width: 1px;
        }
      `}
  }
`;

interface InputStickyContainerProps {
  sticky?: boolean;
  background?: string;
}

export const InputStickyContainer = styled.div<InputStickyContainerProps>`
  &&& {
    position: relative;
    background: ${({ background }) => background ?? 'inherit'};

    ${({ sticky }) =>
      sticky &&
      css`
        & {
          position: sticky;
          top: 0;
          z-index: 9;
        }
      `}
  }
`;

export const InputContainer = styled.div`
  &&& {
    position: relative;
  }
`;

export const InputClearButton = styled.button`
  &&& {
    padding: 0;
    border: none;
    background: transparent;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const ErrorMessage = styled.div`
  &&& {
    color: ${NEGATIVE_500};
    ${BODY_SMALL_REGULAR};

    ${() =>
      isAmazonHost() &&
      css`
        @media (max-width: 768px) {
          font-size: 13px !important;
          line-height: 16px !important;
        }
      `}
  }
`;
