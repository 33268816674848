import { FunctionComponent, ReactNode } from 'react';
import {
  BackButton,
  HeaderContainer,
  TitleContainer,
  Title,
} from './Header.styles';
import { iconUrl, isAmazonHost } from '../../utils';
import { useTranslation } from 'react-i18next';

interface Props {
  hasBackButton: boolean;
  onBack: () => void;
  headerTitle: string;
  children?: ReactNode;
}

const Header: FunctionComponent<Props> = (props) => {
  const { children, onBack, hasBackButton, headerTitle } = props;
  const { t } = useTranslation('common');

  return (
    <HeaderContainer className="row" isAmazon={isAmazonHost()}>
      {!isAmazonHost() && (
        <div className="col-12">
          <TitleContainer className="row align-items-center">
            {hasBackButton && (
              <BackButton type="button" className="btn" onClick={onBack}>
                <img src={iconUrl.chevronLeft} alt={t('header.back')}></img>
                <span className="d-none d-md-inline-block">
                  {t('header.back')}
                </span>
              </BackButton>
            )}

            <div className="col text-center">
              <Title>{headerTitle}</Title>
            </div>
          </TitleContainer>
        </div>
      )}
      <div className="col-12">
        <div className="row">{children}</div>
      </div>
    </HeaderContainer>
  );
};

export default Header;
