import { createContext, useState } from 'react';
import { Brand } from '../api';

interface BrandsProviderProps {
  children?: React.ReactNode;
}

interface BrandContextProps {
  brands?: Brand[];
  updateBrands?: (values: Brand[]) => void;
}

export const BrandsContext = createContext<BrandContextProps>({});

export const BrandsProvider = ({ children }: BrandsProviderProps) => {
  const [brands, setBrands] = useState<Brand[]>();

  const updateBrands = (values: Brand[]) => {
    setBrands(values);
  };

  return (
    <BrandsContext.Provider value={{ brands, updateBrands }}>
      {children}
    </BrandsContext.Provider>
  );
};
