import { FunctionComponent, ReactNode } from 'react';
import PreferHuman from '../PreferHuman/PreferHuman';
import {
  ImageContainer,
  PreferHumanContainer,
  StepContent,
  StepContentContainer,
} from './Step.styles';
import { StepKey } from '../../types';
import { useMetrics, useTimeSpentMetric } from '../../hooks';
import { MetricProperty, isAmazonHost, metricEvents } from '../../utils';

interface StepProps {
  children: ReactNode;
  preferHuman?: boolean;
  image?: ImageData;
  stepKey?: StepKey;
}

export interface ImageData {
  source: string;
  altText: string;
}

const Step: FunctionComponent<StepProps> = (props) => {
  const { preferHuman, image, stepKey } = props;
  const { track } = useMetrics();

  useTimeSpentMetric(stepKey as string);

  const handleVisitSupportPageClick = () => {
    track(metricEvents.supportPageLink, {
      [MetricProperty.pageName]: stepKey,
    });
  };

  return (
    <div className="row h-100">
      {image && !isAmazonHost() && (
        <ImageContainer className="col-6 col-lg-7 col-xl-8 d-none d-md-flex image-container h-100 justify-content-center">
          <img
            src={image.source}
            alt={image.altText}
            className="img-fluid align-self-center"
          />
          {preferHuman && (
            <PreferHumanContainer>
              <PreferHuman onClick={handleVisitSupportPageClick} />
            </PreferHumanContainer>
          )}
        </ImageContainer>
      )}
      <StepContentContainer className="col h-100" id="step-content">
        {preferHuman && !isAmazonHost() && (
          <div className="d-md-none">
            <PreferHuman onClick={handleVisitSupportPageClick} />
          </div>
        )}
        <StepContent className="row" preferHuman={preferHuman}>
          <div className="mt-4 col gx-5">{props.children}</div>
        </StepContent>
      </StepContentContainer>
    </div>
  );
};

export default Step;
