import React, { useContext, useEffect, useState } from 'react';
import {
  ErrorMessage,
  Input,
  InputClearButton,
  InputContainer,
  InputStickyContainer,
  Question,
} from '../../App.styles';
import { useTranslation } from 'react-i18next';
import { ScreenOutcomeType, useHandleOutcome, useMetrics } from '../../hooks';
import { StepperContext } from '../../contexts';
import { ModelVariant } from '../../api';
import { InfoBox, NavFooter } from '../';
import RadioGroup, { RadioOption } from '../RadioGroup/RadioGroup';
import { AppOutcome, StepKey } from '../../types';
import {
  MetricProperty,
  TestId,
  getModelOutcome,
  getSearchResult,
  iconUrl,
  isAmazonHost,
  isNil,
  metricEvents,
} from '../../utils';
import Button from '../Button';
import { ReasonOutcomeType } from '../../hooks/useHandleOutcome';

interface BackplateSelectionProps {
  onBack: () => void;
}

const BackplateSelection: React.FC<BackplateSelectionProps> = ({ onBack }) => {
  const { t } = useTranslation('common');
  const { track } = useMetrics();
  const { handleOutcome } = useHandleOutcome();
  const { stepperData, updateStepperData } = useContext(StepperContext);
  const { brand, model } = stepperData;
  const [filter, setFilter] = useState('');
  const [variantList, setVariantList] = useState<RadioOption<ModelVariant>[]>(
    []
  );
  const [variant, setVariant] = useState<ModelVariant>();
  const [populated, setPopulated] = useState(false);
  const [allVariants, setAllVariants] = useState<RadioOption<ModelVariant>[]>(
    []
  );

  useEffect(() => {
    if (model?.variants) {
      setAllVariants(
        model?.variants
          .filter((variant) => variant.backplate_number)
          .map((variant, index) => ({
            id: index.toString(),
            label: variant.backplate_number,
            value: variant,
          }))
      );
    }
  }, [model]);

  useEffect(() => {
    if (variant) {
      setFilter(variant.backplate_number);
    }
  }, [setFilter]);

  useEffect(() => {
    const filteredVariants = getSearchResult(allVariants, 'label', filter);
    setVariantList(filteredVariants);
  }, [filter, allVariants]);

  useEffect(() => {
    if (!isNil(stepperData?.variant) && isNil(variant) && !populated) {
      setPopulated(true);
      setVariant(stepperData?.variant);
      if (!isNil(stepperData?.variant?.backplate_number)) {
        setFilter(stepperData?.variant?.backplate_number as string);
      }
    }
  }, [stepperData, variant, populated]);

  const handleModelSelectionOutcome = (
    outcome: AppOutcome,
    reason: ReasonOutcomeType,
    searchText?: string
  ) => {
    handleOutcome({
      value: outcome,
      screen: ScreenOutcomeType.SelectBackplate,
      reason,
      brand,
      model,
      variant,
      searchText,
    });
  };

  const handleNeedHelp = () => {
    track(metricEvents.needHelpBtn, {
      [MetricProperty.pageName]: StepKey.BackplateSelection,
    });
    handleModelSelectionOutcome(
      AppOutcome.InfoNeeded,
      ReasonOutcomeType.HelpClick
    );
  };

  return (
    <div
      className="row h-100 flex-column"
      data-testid={TestId.BackplateSelection}
    >
      <Question className="col-12">{t('prompts.backplateSelection')}</Question>

      <div className="col-12 mt-3">
        <InfoBox
          title={t('help.backplate.title')}
          description={t('help.backplate.description')}
        ></InfoBox>
      </div>

      <InputStickyContainer className="col-12 py-3" sticky background="white">
        <InputContainer>
          {filter.length > 0 && (
            <InputClearButton
              aria-label="close"
              onClick={() => {
                setFilter('');
                setVariant(undefined);
              }}
            >
              <img src={iconUrl.close} alt={t('textFields.closeBtn')} />
            </InputClearButton>
          )}
          <Input
            type="text"
            hasError={!variantList.length}
            placeholder={t('placeholders.modelFilter')}
            className="form-control"
            value={filter}
            onChange={(e) => {
              setVariant(undefined);
              setFilter(e.target.value);
            }}
          />
        </InputContainer>
        {!variantList.length && (
          <ErrorMessage className="mt-3">
            {t('help.noResults.errorMessage')}
          </ErrorMessage>
        )}
      </InputStickyContainer>

      <div className="flex-grow-1">
        {variantList.length ? (
          <RadioGroup
            groupName="variants"
            options={variantList}
            value={variant}
            setValue={(val) => setVariant(val)}
          ></RadioGroup>
        ) : (
          <span
            role="button"
            className="d-block mt-3"
            onClick={() => {
              const searchText = filter.toLowerCase().trim();
              handleModelSelectionOutcome(
                AppOutcome.InfoNeeded,
                ReasonOutcomeType.NoSearchResultsClick,
                searchText
              );
            }}
          >
            <InfoBox
              isHelp
              title={t('help.noResults.title')}
              description={t('help.noResults.description')}
            ></InfoBox>
          </span>
        )}
      </div>

      <NavFooter>
        <Button
          type="button"
          disabled={!variant}
          onClick={() => {
            if (variant) {
              updateStepperData({ variant });
              handleModelSelectionOutcome(
                getModelOutcome(variant.support),
                ReasonOutcomeType.Complete
              );
            }
          }}
        >
          {t('buttons.continue')}
        </Button>
        {isAmazonHost() && (
          <>
            <Button type="button" ghost onClick={onBack}>
              {t('header.back')}
            </Button>
            <div style={{ marginRight: 'auto' }} />
          </>
        )}
        <Button type="button" ghost onClick={handleNeedHelp}>
          {t('buttons.needHelp')}
        </Button>
      </NavFooter>
    </div>
  );
};

export default BackplateSelection;
