export const getUserAgentInfo = () => {
  const ua = window?.navigator?.userAgent;

  if (ua === undefined) {
    return {};
  }

  return {
    isIOS: /iPad|iPhone|iPod/.test(ua),
    isAndroid: /Android/.test(ua),
  };
};
