import { FunctionComponent } from 'react';
import { Spinner, SpinnerContainer } from './LoadingIndicator.styles';
import { iconUrl } from '../../utils';

const LoadingIndicator: FunctionComponent = () => {
  return (
    <SpinnerContainer>
      <Spinner src={iconUrl.loading}></Spinner>
    </SpinnerContainer>
  );
};

export default LoadingIndicator;
