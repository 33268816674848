import { Input, Label, Container } from './Checkbox.styles';
import { iconUrl, isAmazonHost } from '../../utils';

interface CheckboxProps {
  className?: string;
  defaultChecked?: boolean;
  onClick?: () => void;
  label?: string;
  id: string;
}

const Checkbox = ({
  id,
  label,
  onClick,
  defaultChecked,
  ...props
}: CheckboxProps) => {
  const isIcon = () => {
    if (label && /[\uec23-\uec2f]+/g.test(label)) {
      return true;
    }

    return false;
  };

  const isAmazon = isAmazonHost();

  return (
    <Container active={defaultChecked} isAmazon={isAmazon} {...props}>
      <Input
        type="checkbox"
        aria-label="checkbox"
        id={id}
        onClick={onClick}
        defaultChecked={defaultChecked}
      ></Input>
      {!isAmazon && (
        <div>
          {defaultChecked ? (
            <img src={iconUrl.checkboxFilled} alt="checkbox filled"></img>
          ) : (
            <img src={iconUrl.checkboxEmpty} alt="checkbox"></img>
          )}
        </div>
      )}
      <Label
        htmlFor={id}
        onClick={onClick}
        isIcon={isIcon()}
        isAmazon={isAmazon}
        active={defaultChecked}
      >
        {label}
      </Label>
    </Container>
  );
};

export default Checkbox;
