export enum GlobalActionType {
  UpdateFlowStep = 'UpdateFlowStep',
}

export enum FlowStep {
  compatibilityChecker = 'compatibilityChecker',
  notCompatible = 'notCompatible',
  success = 'success',
  infoNeeded = 'infoNeeded',
}

export type GlobalStore = {
  flowStep: FlowStep;
};

export const defaultGlobalStore: GlobalStore = {
  flowStep: FlowStep['compatibilityChecker'],
};
