import axios from 'axios';
import { environment } from '../utils/environment';
import { getMarketplaceCountry } from './utils';

const idsAxios = axios.create({
  baseURL: environment.basePath
});

idsAxios.interceptors.request.use(
  config => {
    config.headers = {
      ...config.headers,
      'X-Market-Region': getMarketplaceCountry()
    };
    return config;
  }
);

export default idsAxios;
