// https://developers.heap.io/reference

export interface Heap {
  addEventProperties: (properties: Record<string, unknown>) => void;
  addUserProperties: (properties: Record<string, unknown>) => void;
  appid: string;
  clearEventProperties: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: any;
  identify: (identity: string) => void;
  identity: string | null;
  load: (appId: string, properties?: Record<string, unknown>) => void;
  loaded: boolean;
  removeEventProperty: (property: string) => void;
  resetIdentity: () => void;
  track: (event: string, properties?: Record<string, unknown>) => void;
  userId: string;
}

declare global {
  interface Window {
    heap: Heap;
  }
}

window.heap = window.heap || {};
