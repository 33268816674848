const NODE_ENV = process.env['NODE_ENV'];
export const CC_PRIVACY_COOKIE = 'cc-privacy-cookie';
export const PRIVACY_COOKIE = 'privacy-cookie';

export const isLocalBuild = NODE_ENV === 'development';
export const isDeployedBuild = NODE_ENV === 'production';

const { hostname } = window.location;

export const isProd = hostname === 'ring.com';
export const isQA = hostname === 'www.qa.ring.com';
export const isDev =
  hostname === 'www.dev.ring.com' || hostname === 'localhost';

export const metricEventPrefix = 'RCC -';
export const metricEvents = {
  choice: `${metricEventPrefix} Choice`,
  selectedBrand: `${metricEventPrefix} Selected Brand`,
  brandNotFound: `${metricEventPrefix} Brand Not Found`,
  selectedModel: `${metricEventPrefix} Selected Model`,
  modelNotFound: `${metricEventPrefix} Model Not Found`,
  outcome: `${metricEventPrefix} Outcome`,
  selectedBrandBeforeTerminal: `${metricEventPrefix} Selected Brand before terminal selection`,
  terminalSelected: `${metricEventPrefix} Terminal selected`,
  searchPreference: `${metricEventPrefix} Search Preference`,
  searchPreferenceSkipped: `${metricEventPrefix} Search Preference Skipped`,
  tryOtherSchemeBtn: `${metricEventPrefix} Click on try other scheme preference button`,
  needHelpBtn: `${metricEventPrefix} Click on I need help button`,
  supportPageLink: `${metricEventPrefix} Click on Visit our Support Page`,
  showMeBtn: `${metricEventPrefix} Click on Show me how to do this`,
  loadAllBtn: `${metricEventPrefix} Click on Load All`,
  noTerminalsBtn: `${metricEventPrefix} Click on My port labels do not appear on the list`,
  exampleBtn: `${metricEventPrefix} Click on Example in port labels selection`,
  appLoad: `${metricEventPrefix} App load`,
  pageView: `${metricEventPrefix} Page view`,
  timeSpent: `${metricEventPrefix} Time spent`,
};

export enum MetricProperty {
  enteredBrandText = 'enteredBrandText',
  selectedModel = 'selectedModel',
  selectedBrand = 'selectedBrand',
  multiDoor = 'multiDoor',
  parallel = 'parallel',
  question = 'question',
  groupName = 'groupName',
  choice = 'choice',
  outcomeValue = 'outcomeValue',
  enteredModelText = 'enteredModelText',
  screen = 'screen',
  terminals = 'terminals',
  knowModelNumber = 'knowModelNumber',
  selectedTerminal = 'selectedTerminal',
  sessionTime = 'sessionTime',
  backplate = 'backplate',
  searchPreference = 'searchPreference',
  pageName = 'pageName',
  reason = 'reason',
  searchText = 'searchText',
}

export const REDIRECT_URL_PARAM = 'redirectUrl';

export const redirectUrls = [
  {
    url: 'https://www.amazon.co.uk/ring-intercom-by-amazon/dp/B09JB7PLB6?ref_=dp_cct_r_1',
    locale: 'en',
  },
  {
    url: 'https://www.amazon.co.uk/ring-intercom-by-amazon/dp/B0B5FX7JYD?ref_=dp_cct_r_1',
    locale: 'en',
  },
  {
    url: 'https://www.amazon.co.uk/ring-intercom-by-amazon/dp/B0B5FX7QHX?ref_=dp_cct_r_1',
    locale: 'en',
  },
  {
    url: 'https://www.amazon.de/ring-intercom-von-amazon/dp/B09JB7PLB6?ref_=dp_cct_r_1',
    locale: 'de',
  },
  {
    url: 'https://www.amazon.de/ring-intercom-von-amazon/dp/B0B5FX7JYD?ref_=dp_cct_r_1',
    locale: 'de',
  },
  {
    url: 'https://www.amazon.de/ring-intercom-von-amazon/dp/B0B5FX7QHX?ref_=dp_cct_r_1',
    locale: 'de',
  },
  {
    url: 'https://www.amazon.fr/ring-intercom-par-amazon/dp/B09JB7PLB6?ref_=dp_cct_r_1',
    locale: 'fr',
  },
  {
    url: 'https://www.amazon.fr/ring-intercom-par-amazon/dp/B0B5FX7JYD?ref_=dp_cct_r_1',
    locale: 'fr',
  },
  {
    url: 'https://www.amazon.fr/ring-intercom-par-amazon/dp/B0B5FX7QHX?ref_=dp_cct_r_1',
    locale: 'fr',
  },
  {
    url: 'https://www.amazon.it/ring-intercom-di-amazon/dp/B09JB7PLB6?ref_=dp_cct_r_1',
    locale: 'it',
  },
  {
    url: 'https://www.amazon.it/ring-intercom-di-amazon/dp/B0B5FX7JYD?ref_=dp_cct_r_1',
    locale: 'it',
  },
  {
    url: 'https://www.amazon.it/ring-intercom-di-amazon/dp/B0B5FX7QHX?ref_=dp_cct_r_1',
    locale: 'it',
  },
  {
    url: 'https://www.amazon.es/ring-intercom-de-amazon/dp/B09JB7PLB6?ref_=dp_cct_r_1',
    locale: 'es',
  },
  {
    url: 'https://www.amazon.es/ring-intercom-de-amazon/dp/B0B5FX7JYD?ref_=dp_cct_r_1',
    locale: 'es',
  },
  {
    url: 'https://www.amazon.es/ring-intercom-de-amazon/dp/B0B5FX7QHX?ref_=dp_cct_r_1',
    locale: 'es',
  },
];

export const mediaCentralUrl =
  'https://m.media-amazon.com/images/G/01/CosmosWeb/RingtercomCompatibilityChecker';

export const imagesUrl = {
  success: `${mediaCentralUrl}/success.png`,
  hidden_tab: `${mediaCentralUrl}/hidden_tab.png`,
  screw: `${mediaCentralUrl}/screw.png`,
  port_labels: `${mediaCentralUrl}/port_labels.png`,
  info_needed: `${mediaCentralUrl}/info_needed.png`,
  find_model: `${mediaCentralUrl}/find_model.png`,
  not_compatible: `${mediaCentralUrl}/not_compatible.png`,
  pull_off: `${mediaCentralUrl}/pull_off.png`,
  find_brand: `${mediaCentralUrl}/find_brand.png`,
  backplate: `${mediaCentralUrl}/backplate.png`,
  coverRemoved: `${mediaCentralUrl}/coverRemoved.png`,
  example1: `${mediaCentralUrl}/example_1_new.png`,
  example2: `${mediaCentralUrl}/example_2_new.png`,
  example3: `${mediaCentralUrl}/example_3_new.png`,
  exterior: `${mediaCentralUrl}/exterior.png`,
  handsetOff: `${mediaCentralUrl}/handsetOff.png`,
};

export const iconUrl = {
  arrow: `${mediaCentralUrl}/svg-icons/arrow.svg`,
  check: `${mediaCentralUrl}/svg-icons/check.svg`,
  checkboxEmpty: `${mediaCentralUrl}/svg-icons/checkbox-empty.svg`,
  checkboxFilled: `${mediaCentralUrl}/svg-icons/checkbox-filled.svg`,
  chevronLeft: `${mediaCentralUrl}/svg-icons/chevron-left.svg`,
  close: `${mediaCentralUrl}/svg-icons/close.svg`,
  disabledCircle: `${mediaCentralUrl}/svg-icons/disabled_circle.svg`,
  infoDot: `${mediaCentralUrl}/svg-icons/info-dot.svg`,
  loading: `${mediaCentralUrl}/svg-icons/loading.svg`,
  phoneCircle: `${mediaCentralUrl}/svg-icons/phone-circle.svg`,
  questionMark: `${mediaCentralUrl}/svg-icons/question_mark.svg`,
  questionDot: `${mediaCentralUrl}/svg-icons/question_dot.svg`,
};
