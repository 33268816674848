import { useEffect } from 'react';
import { useHeap, usePrivacyCookie, useConsentManager } from './';
import { isAmazonHost } from '../utils';

export const useThirdPartyAnalytics = () => {
  const isTrackingAccepted = useConsentManager();
  const isHeapAllowed = usePrivacyCookie('analytics_heap');
  const { load, addAuthEventProperties } = useHeap();

  useEffect(() => {
    /**
     * Heap will be enabled when one of the following use cases ocurrs (only on r.com):
     * - User has allowed heap tracking before (we can check this through the privacy cookie)
     * - User clicks the "accept" button in the consent manager banner
     */
    if (!isAmazonHost() && (isHeapAllowed || isTrackingAccepted)) {
      load();
    }
  }, [isHeapAllowed, isTrackingAccepted, load, addAuthEventProperties]);
};
