import { FunctionComponent, useContext, useEffect } from 'react';
import { Props } from 'framer-motion/types/types';
import LoadingIndicator from '../LoadingIndicator';
import { getModels, listModels, SchemePreference, SupportType } from '../../api';
import { getModelOutcome, TestId } from '../../utils';
import { StepperContext } from '../../contexts';
import { ScreenOutcomeType, useHandleOutcome } from '../../hooks';
import { Outcome, ReasonOutcomeType } from '../../hooks/useHandleOutcome';
import { AppOutcome } from '../../types';

const ModelLoadingEmpty: FunctionComponent<Props> = () => {
  const { stepperData, updateStepperData } = useContext(StepperContext);
  const {
    multiDoor,
    isParallel,
    brand,
    model: contextModel,
    terminals,
    activeStep,
    searchBy,
    variant: contextVariant,
    hasBackplate,
    candidate,
    fallbackToModel
  } = stepperData;
  const { handleOutcome } = useHandleOutcome();

  useEffect(() => {
    const getData = async () => {
      if (!brand) {
        throw new Error('Invalid context. Missing Brand.');
      }

      let support = undefined as SupportType | undefined;
      let outcome = {
        brand,
        screen: ScreenOutcomeType.Question,
        reason: ReasonOutcomeType.CompleteAfterSuccess,
      } as Outcome;

      if (searchBy === SchemePreference.ModelName || fallbackToModel) {
        const data = await getModels(
          brand as string,
          multiDoor as boolean,
          isParallel as boolean,
        );

        const model = data.find(m => m.model_name === contextModel?.model_name);
        support = model?.support;
        let variant = undefined;
        if (hasBackplate) {
          variant = model?.variants?.find(v => v.backplate_number === contextVariant?.backplate_number);
          support = variant?.support;
        }
        outcome = { ...outcome, model, variant };
      } else {
        if (!terminals?.length) {
          throw new Error('Invalid context. Missing terminals.');
        }

        const models = await listModels(brand, terminals, !!multiDoor, !!isParallel);
        support = models.estimated_support_status;
        if (candidate) {
          support = models.candidates.find(c => c.model_name === candidate.model_name)?.support;
        }
        outcome = { ...outcome, terminals }
      }

      if (!support) {
        throw new Error('Invalid data. Support value is undefined.');
      }

      outcome.value = getModelOutcome(support);
      handleOutcome(outcome);
    };
    getData()
      .then(() => updateStepperData({ activeStep: activeStep - 1 }))
      .catch(() => handleOutcome({
        value: AppOutcome.InfoNeeded,
        screen: ScreenOutcomeType.Question,
        reason: ReasonOutcomeType.APIError,
        brand,
      }));

  }, [brand, multiDoor, isParallel, hasBackplate, searchBy]);


  return <div className="w-100 h-100" data-testid={TestId.ModelLoadingEmpty}><LoadingIndicator /></div>;
};

export default ModelLoadingEmpty;
