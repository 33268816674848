import { FunctionComponent, useContext, useState } from 'react';
import { Question } from '../../App.styles';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import RadioGroup from '../RadioGroup';
import { RadioOption } from '../RadioGroup/RadioGroup';
import NavFooter from '../NavFooter/NavFooter';
import { ScreenOutcomeType, useHandleOutcome, useMetrics } from '../../hooks';
import { AppOutcome, StepKey } from '../../types';
import { StepperContext } from '../../contexts';
import {
  MetricProperty,
  TestId,
  isAmazonHost,
  metricEvents,
} from '../../utils';
import { ReasonOutcomeType } from '../../hooks/useHandleOutcome';

interface Props {
  next: () => void;
  onBack: () => void;
}

const HasBackplate: FunctionComponent<Props> = ({ next, onBack }) => {
  const { t } = useTranslation('common');
  const { track } = useMetrics();
  const [hasBackplate, setHasBackplate] = useState<boolean | undefined>();
  const { handleOutcome } = useHandleOutcome();
  const { stepperData } = useContext(StepperContext);
  const { brand, model } = stepperData;

  const options: RadioOption<boolean>[] = [
    {
      id: 'yes',
      label: t('responses.hasBackplateYes'),
      value: true,
    },
    {
      id: 'no',
      label: t('responses.hasBackplateNo'),
      value: false,
    },
  ];

  const handleClick = (reason: ReasonOutcomeType) => {
    handleOutcome({
      value: AppOutcome.InfoNeeded,
      screen: ScreenOutcomeType.HasBackplate,
      reason,
      brand,
      model,
    });
  };

  const handleNeedHelp = () => {
    track(metricEvents.needHelpBtn, {
      [MetricProperty.pageName]: StepKey.HasBackplate,
    });
    handleClick(ReasonOutcomeType.HelpClick);
  };

  return (
    <div className="row h-100 flex-column" data-testid={TestId.HasBackplate}>
      <Question className="col-12 mb-3">{t('prompts.hasBackplate')}</Question>

      <div className="flex-grow-1">
        <RadioGroup
          groupName="hasBackplate"
          options={options}
          value={hasBackplate}
          setValue={setHasBackplate}
        ></RadioGroup>
      </div>

      <NavFooter>
        <Button
          type="button"
          disabled={hasBackplate === undefined}
          onClick={() => {
            if (hasBackplate) {
              next();
            } else {
              handleClick(ReasonOutcomeType.NoBackplate);
            }
          }}
        >
          {t('buttons.continue')}
        </Button>
        {isAmazonHost() && (
          <>
            <Button type="button" ghost onClick={onBack}>
              {t('header.back')}
            </Button>
            <div style={{ marginRight: 'auto' }} />
          </>
        )}
        <Button type="button" ghost onClick={handleNeedHelp}>
          {t('buttons.needHelp')}
        </Button>
      </NavFooter>
    </div>
  );
};

export default HasBackplate;
