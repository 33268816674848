import { AxiosError } from 'axios';
import { Brand } from './getModels';
import idsAxios from './idsAxios';

const getBrands = async () => {
  try {
    const res = await idsAxios.get('/brands');
    return res.data as Brand[];
  } catch (error) {
    const message = (error as AxiosError)?.message || undefined;
    const code = (error as AxiosError)?.code || undefined;
    throw new AxiosError(message, code);
  }
};
export default getBrands;
