import { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Step, { ImageData } from '../Step/Step';
import Stepper from '../Stepper/Stepper';

interface Props {
  children: ReactNode;
  image?: ImageData;
}

const Result: FunctionComponent<Props> = (props) => {
  const { children, image } = props;
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  return (
    <Stepper
      headerTitle={t('results.title')}
      hasBackButton={true}
      activeStep={0}
      onBack={() => navigate(-1)}
    >
      <Step image={image} preferHuman>
        {children}
      </Step>
    </Stepper>
  );
};

export default Result;
